<template src="./classic.html"></template>
<style src="./style-pc-default.css" scoped></style>
<script>
import ClassicCore from '../ClassicCore.vue';
import SlotSwipper from "../../../components/SlotSwipper";
import Init from '../../utils/Init';

export default{
    name:'classic-page-desktop',
    extends:ClassicCore,
    components: {
     SlotSwipper
  },
  methods:{
    onBannerClick(slide_id){
      let options = Init.getOptions();
      let usertoken=options.usertoken;
      
      if(usertoken && usertoken.length > 128 ){
          switch(slide_id){
          case 'spaceman': this.launchGame('pg', 'Pragmatic', '1301', 'wb', 50473); break;
          case 'aviator': this.launchGame('tgg', 'Spribe', '6109_TGG', 'wb', 50418); break;
          case 'peaky_blinder': this.launchGame('pg', 'Pragmatic', 'vs20pblinders', 'wb', 50461); break;
          case 'roulette': this.launchGame('pg', 'Pragmatic', '234', 'wb', 50529);break;
          case 'crash_game': this.showGames('crash_game');break;
          case 'slot': this.showGames('slot');break;
          case 'slot_live': this.showGames('slot_live');break;
          case 'hipica': this.showGames('hipica');break;
          case 'sportbook': this.showGames('sportbook');break;
          case 'esports': this.showGames('esports');break;
          
        }
      }else{
        if(/futbol|basket|tenis|sportbook/.test(slide_id)) this.showGames('sportbook')
        else if(slide_id=='slot') this.showGames(slide_id)
        else if(slide_id=='slot_live') this.showGames(slide_id)
        else if(slide_id=='christmas') this.gotoSection('NUEV')
        else{
            this.nologinevent(); return;
        }
         
           
      }
    
  }
  }
}
</script>
<style scoped>

</style>