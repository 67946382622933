import { createApp } from 'vue'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import bootstrap styles */
//import "bootstrap/dist/css/bootstrap.min.css"
//import "bootstrap"
/* import specific icons */
import { faUserSecret, faSearch, faAnglesUp, faAnglesDown, faPlay, faHeart as faHeartSolid, faAngleDoubleLeft, faAngleDoubleRight, faCoins, faXmark, faChevronUp, faBars, faUser as faUserSolid, faHome, faClipboardCheck, faSearchDollar, faArrowLeft, faCaretDown, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import { faHeart, faUser } from '@fortawesome/free-regular-svg-icons'
library.add([faUserSecret,faSearch,faHeart,faAnglesUp,faAnglesDown, faPlay, faHeartSolid, faAngleDoubleLeft,faCoins, faXmark, faChevronUp, faUser,faBars, faUserSolid, faHome, faClipboardCheck, faSearchDollar, faArrowLeft, faAngleDoubleRight,faCaretDown,faChevronRight ])
window.UniversalApp=createApp(App).component('font-awesome-icon', FontAwesomeIcon).mount('#universal-lobby-app');
//console.log("app ===== ",window.UApp.isMobile());
