/*function onNologin(){
    alert('no usertoken');
}
onNologin();*/


export default {
    getOptions(){
        let opt=null;
        if(!window['OPTIONS']){
            opt={
                //usertoken:'1bb1ed735c78b05ff93fc0bf8324ee8934ddc96ab5608cfe7387cbe89c6a3109a0e2dc9c787bc7a404b40b6d16a784a2c5ed38a84a2ba2ade65645cd34d79263',
                //lang:'fr',
                //category:'sportbook',
                onNoLogin: function(){ eval('onNoLogin()')},
                onReturnHome: ()=>{ eval('onReturnHome()') }
            };
            window['OPTIONS'] = opt;
        }else{
            opt=window['OPTIONS'];

        }
        opt.env=opt.env||1;  //1 para produccion --   0 para test
        opt.API_URL='-nd.apiusoft.com';
        opt.ASSETS_URL='https://assets.apiusoft.com';
        opt.category=opt.category||'home';
        opt.lang=opt.lang||'es';
        opt.theme=opt.theme||'default';
        opt['X-Tenant'] = opt['X-Tenant']||'';
        //opt.BACKEND_URL= 'http://localhost:5000'
        opt.BACKEND_URL='https://lobby'+( opt.env == 1 ? '' : '-test')+'.apiusoft.com';
        opt.BACKEND_URL_NODE='https://'+( opt.env == 1 ? 'prod' : 'test')+opt.API_URL;
        opt.API_URL='https://'+( opt.env == 1 ? 'prod' : 'test') + opt.API_URL;
        opt.TK=opt.TK || '1910204347435ygdmpdvlysrmhuzgfvl8yrqmradnl7csexrpjetb71l7todr3jid6rgdengj9njw4w6e0ej7jpmirk7smn3eboag0tbaxt76pc4c4qmy93io22ous7b8scjevp6jhjd3';
        opt.IS_MOBILE = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        
        return opt;
    },

    

    /*pleasework: function(app){
        app.isMobile();
        console.log("pleasework fired")
    }*/
    
}