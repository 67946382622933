<script>
import Ut from './utils/Ut';
  export default {
  props: ['ASSETS_URL', 'onGametypeChange', 'onShowGames', 'lang'],
    data() {
      return {
        slidesPerView_:1,
      }
    },
    methods:{
      gametypeChange(game_type){
        this.onGametypeChange(game_type);
      },
      showGames(category){
        this.onShowGames(category);
      },
      tr(code, lang) {
          return Ut.tr(code, lang);
      },
    },
    mounted(){
      if(this.slidesPerView!=null) this.slidesPerView_= this.slidesPerView

    }
  };
</script>
<template>
  <div id="new-menu-carousel" class="content-gametype-slotlive">
    <div class="gametype-slotlive">
      <button class="btn" @click="showGames('slot_live')">
        <i style="font-size: 1.7rem;"><font-awesome-icon icon="fa-solid fa-coins" /></i>
        <span class="title-gametype">{{ tr("all", lang)}}</span>
      </button>
      <button class="btn" @click="gametypeChange('RLIV')">
        <img class="universal-img-fluid " :src="`${ASSETS_URL}/us_img_slot_live/RULETA VIVO.png`">
        <span class="title-gametype">{{ tr("ROUL", lang) }}</span>
      </button>
      <button class="btn" @click="gametypeChange('BJLIV')">
        <img class="universal-img-fluid " :src="`${ASSETS_URL}/us_img_slot_live/BJ LIVE.png`">
        <span class="title-gametype">{{ tr("BJACK", lang) }}</span>
      </button>
      <button class="btn" @click="gametypeChange('BACC')">
        <img class="universal-img-fluid " :src="`${ASSETS_URL}/us_img_slot_live/BACCARAT.png`">
        <span class="title-gametype">{{ tr("BACC", lang) }}</span>
      </button>
      <button class="btn" @click="gametypeChange('MWLIV')">
        <img class="universal-img-fluid " :src="`${ASSETS_URL}/us_img_slot_live/MEGAWHEEL.png`">
        <span class="title-gametype">{{ tr("mwliv", lang) }}</span>
      </button>
      <button class="btn" @click="gametypeChange('LOTTO')">
        <img class="universal-img-fluid " :src="`${ASSETS_URL}/us_img_slot_live/LOTTERY.png`">
        <span class="title-gametype">{{ tr("lotto", lang) }}</span>
      </button>
      <button class="btn" @click="gametypeChange('SICBO')">
        <img class="universal-img-fluid " :src="`${ASSETS_URL}/us_img_slot_live/SICBO.png`">
        <span class="title-gametype">{{ tr("SICBO", lang) }}</span>
      </button>
    </div>
  </div>
</template>

<style scoped>
.gametype-slotlive{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: scroll;
  gap: 0.1rem;
}
.btn{
  width: 100%;
  background-color: #333;
  border: none;
  padding: 0.5rem;
  color: white;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-gametype{
  text-transform: capitalize;
}
.btn img{
  filter: none;
}

.btn.active {
  background: #ff2020;
  border-radius: 5px;
  padding: 0.3rem 0.5rem;
  color: white;
}


</style>