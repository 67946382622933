<div class='body-bg1'>
    <div style="text-align: center; content: center; margin-top:40vh" v-if="preloader">
        <div v-if="preloader" :class="pagetheme == 'white' ? 'lds-ripple-purple' : 'lds-ripple-white'">
            <div></div>
            <div></div>
        </div>
    </div>

    <div v-if="!preloader">
        <div style="display: none;" v-if="showMenu==1" id="navbarSupportedContent">
            <ul class="universal-navbar-nav ml-auto">
                <li class="nav-item" style="text-align: center;padding: 1rem;">
                    <a class="nav-link" href="javascript:void(0);" @click="showGames('home')"><img class="universal-img-fluid " style="width: 145px;" :src="client.logo" alt="logo" /></a>
                </li>
                <li class="nav-item"><a class="nav-link" style="color:white;padding-top: 0;" href="javascript:void(0);">
                    <span>{{user.firstname}}</span>&nbsp;<span>{{user.currency}}</span>&nbsp;<span>{{user.balance}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0);" @click="showGames('home')"><i><font-awesome-icon icon="fas fa-home" /></i> Inicio</a> 
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0);" @click="showUserModal('betListModal')" data-target="#user-apuestas">
                        <i><font-awesome-icon icon="fas fa-clipboard-check" /></i> {{tr("BETS")}}
                    </a> 
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0);" @click="showUserModal('movListModal')">
                        <i> <font-awesome-icon icon="fas fa-search-dollar" /> </i>{{tr("MOVE")}}
                    </a>
                </li>
                <li class="nav-item" style="background: #b90000;">
                    <a class="nav-link" href="javascript:void(0);" @click="showUserModal('navbarSupportedContent')" style="color: white;">
                        <i><font-awesome-icon icon="fas fa-arrow-left"/></i>{{tr("CLOSE")}}
                    </a>
                </li>
            </ul>
        </div>

        <div v-if="showMenu==0 && active_view != 'sportbook' && active_view != 'home'">
            <a href="javascript:void(0);" @click="returnToHome()" class="right-back-bar-menu no-desktop" :style="{color: textColor, background: clientColor}"> {{tr(active_view)}} <font-awesome-icon icon="fa-solid fa-angles-left" /></a>
        </div>
        
        <!--Inicio Menu category-->
        <div id="bartop-movil" v-show="showMenu==1" class="buttons-menu-option">
            <div class="category-buttons-container">
                <div v-for="cat in CATEGORIES" :key="cat.code" class="category-button">
                    <button class="btn" :id="cat.code+'-u-category-menu'" @click="showGames(cat.code)">
                        <img class='universal-img-fluid img-bar-top' :src="ASSETS_URL+'/us_img_categories/'+cat.code+'.png'" alt=''>
                        <p class='bar-top-p'>{{tr(cat.code)}}</p>
                    </button>
                </div>
            </div>
        </div>
        <!-- Fin Menu category-->

        <!-- ---------BANNERS------------------- -->
        <div class="">
            <div v-if="active_view=='home'">
                <SlotSwipper :slides="slotSlides" :navigation="false" :onClick="onBannerClick" />
            </div>
            <div v-if="active_view=='slot'">
                <SlotSwipper :slides="slotSlides" :onClick="onBannerClick" />
            </div>
            <div v-if="active_view=='slot_live'">
                <SlotSwipper :slides="slotliveSlides" :onClick="onBannerClick" />
            </div>
            <div v-if="active_view=='scratch'" v-cloak>
                <SlotSwipper :slides="scratchSlides" :onClick="onBannerClick" />
            </div>
            <div v-if="active_view=='virtual'" v-cloak>
                <SlotSwipper :slides="virtualSlides" :onClick="onBannerClick" />
            </div>
            <div v-if="active_view=='crash_game'" v-cloak>
                <SlotSwipper :slides="crashGamesSlides" :onClick="onBannerClick" />
            </div>
        </div>
        <!-- ---------------------------- -->

        <div class="jackpot-bg" v-if="active_view=='slot'">
            <!-- TIPOS DE JUEGO -->
            <div id="old-menu-carousel" class="new-carousel dragscroll">
                <ul id="ys_showtipos" class="new-carousel-ul">
                    <li @click="listAllGames()" style="cursor:pointer">
                        <div class="new-carousel-div">{{tr("ALL")}}</div>
                    </li>
                    <li v-for="item in SLOT_GAME_SECTIONS.filter(e=>{ return e.showInFilter && e.views.find(c => c == active_view)})" @click="currentCategoryFilter = item.name; onGametypeChange(item.code)" style="cursor:pointer">
                        <div class="new-carousel-div">{{tr(item.code)}}</div>
                    </li>
                </ul>
            </div>
            <!-- FIN TIPOS DE JUEGO -->
            <div id="sidebar-menu" class="universal-row universal-no-gutters">
                <!-- SELECCION DE DESPLIEGUE -->
                <div style="display: none;" class="column-mb-6-search-form">
                    <div class="jackpot-list-2 popup">
                        <div class="toggle-button-cover">
                            <label class="label-site" for="site">{{tr("PUP")}}</label>
                            <div class="button r" id="button-1">
                                <input id="changecolor" type="checkbox" class="checkbox">
                                <div class="knobs"></div>
                                <div class="layer"></div>
                            </div>
                            <label class="label-site" for="popup">{{tr("OSITE")}}</label>
                        </div>
                    </div>
                </div>
                <!-- FIN DE SELECCION DE DESPLIEGUE -->
                <!-- BUSQUEDA -->
                <div class="column-mb-6-search-form " style="margin-bottom: 2.5px;display: none;">
                    <div class="jackpot-list-2 searchtipe">
                        <div class="search-div search-casino">
                            <input id="searchcasino" v-model="text_search" class="universal-form-control" type="search" placeholder="Search" aria-label="Search" @keyup="search('slot')" />
                        </div>
                    </div>
                </div>
                <!-- FIN DE BUSQUEDA -->
                <DropdownsProviders  :favs="listFavs" :lang="lang" :category="slot" :listBrands="listBrandsModal" :game_type="slot"></DropdownsProviders>

            </div>
        </div>

        <div class="jackpot-bg" v-if="active_view=='slot_live'">
            <SlotLiveMenu :ASSETS_URL="ASSETS_URL" :onShowGames="showGames" :onGametypeChange="onGametypeChange" :lang="lang"></SlotLiveMenu> 
            <DropdownsProviders  :favs="listFavs" :lang="lang" :category="slot_live" :listBrands="listBrandsModal" :game_type="sl"></DropdownsProviders>
        </div>

        <div class="jackpot-bg" v-if="active_view=='scratch'">
            <div id="old-menu-carousel" class="new-carousel dragscroll">
                <ul id="ys_showtipos" class="new-carousel-ul">
                    <li @click="listAllGames()" style="cursor:pointer">
                        <div class="new-carousel-div">{{tr("ALL")}}</div>
                    </li>
                    <li v-for="item in SLOT_GAME_SECTIONS.filter(e=>{ return e.showInFilter && e.views.find(c => c == active_view)})"
                        @click="currentCategoryFilter = item.name; onGametypeChange(item.code)" style="cursor:pointer">
                        <div class="new-carousel-div">{{tr(item.code)}}</div>
                    </li>
                </ul>
            </div>
            <div id="sidebar-menu-raspa" class="universal-row universal-no-gutters">
                <!-- PROVEEDORES -->
                <div class="column-mb-12">
                    <p class="title-menu-raspa mb-3">{{tr("STITLE")}}</p>
                </div>
                <div class="column-mb-6-search-form ">
                    <p class="w-100 title-menu-raspa">{{tr("SW100")}}</p>
                </div>
                <div class="column-mb-6-search-form ">
                    <select style="color: black;" class="universal-form-control" @change="orderBy($event.target.value)">
                        <option value="scratch" selected>{{tr("SALL")}}</option>
                        <option value="less">{{tr("LESS")}}</option>
                        <option value="higher">{{tr("HIGHER")}}</option>
                    </select>
                </div>
            </div>
        </div>


        <div id="miprovlive" class="provlist" style="display:none;" aria-labelledby="navbarDropdown" v-if="active_view=='slot_live'">
            <a class="universal-dropdown-item" href="javascript:void(0)" :class="(proveedor_select == brand.code ? 'active' : '')" @click="onBrandChange('slot_live',brand.code)"  v-for="brand in slotlive_brands">
                <div style="display:flex; justify-content: space-between;">
                    <span class="Fcasino9">{{brand.name}}</span>
                    <span>({{brand.total}})</span>
                </div>
            </a>
        </div>

        <!-- LISTA DE CAPAS DE JUEGOS -->
        <div id="game-list" class="appear-on-load content-view">
            <!--- SECCIONES SUPERIORES -->
            <section :id='"gamesection-"+game_section.code' :ref='"gamesection-"+game_section.code' class='home-bg pl-0 pt-0 pr-0' v-for="game_section in SLOT_GAME_SECTIONS.filter(e=>e.shown)" v-show="game_section.views.includes(active_view)">
                <div class='universal-row universal-no-gutters category-position'>
                    <div class='column-mb-12'>
                        <p class='title-games' :class="'Ftitle'+game_section.code">{{game_section.code =='BRAND'? game_section.name :tr(game_section.code)}}</p>
                    </div>
                </div>
                <div v-if="!game_section.list.length && !sectionLoader" style="color:white;">{{tr("RYGT5")}}</div>
                <div v-if="sectionLoader && !game_section.list.length" :class="pagetheme == 'white' ? 'lds-ripple-purple' : 'lds-ripple-white'">
                    <div></div>
                    <div></div>
                </div>
                <div :id='"ys_show"+game_section.code' class='universal-row universal-no-gutters universal-position-relative' style="display:flex; justify-content:center;">
                    <div class="column-mb-6 effect-padd" v-for="game in game_section.list">
                        <div class="lazo-maxbet" v-if="game_section.code=='NUEV' || game.isNew==1">NEW</div>
                        <div class="lazo-maxbet" v-if="active_view=='scratch' && game.min_bet > 0 "> {{trCurr(user.currency,'symbol')}} {{game.min_bet.toFixed(2)}}</div>
                        <div class="game_inner game-sm" @click="launchGame(game.provider, game.brand, game.gameid, game.mode, game.id)">
                            <div class="lazo-jackpot" v-if="active_view=='scratch' && game.jackpot > 0 "> {{trCurr(user.currency,'symbol')}} {{game.jackpot}}</div>
                            <img class="universal-img-fluid " style="width: 100%;" :src=buildIMGURL(game) onerror="this.src='https://d2zzz5z45zl95g.cloudfront.net/us_img_games/_no_game1.png'" alt="">
                        </div>
                        <div class="new-text-div">
                            <p class="title-game">{{game.name}}</p>
                            <div class="jackpot-div fav-heart">
                                <span>
                                    <i v-show="!game.isfav" aria-hidden="true" @click="saveFavs(game, 'add')"><font-awesome-icon icon="far fa-heart"/></i>
                                    <i v-show="game.isfav" aria-hidden="true" @click="saveFavs(game, 'remove')"><font-awesome-icon icon="far fa-heart fas"/></i>
                                </span>
                            </div>
                        </div>
                    </div>
        
                    <div class='mostrar-div' style='position: initial;'>
                        <span class='btn-mostrar' @click="viewMore(game_section.code, game_section.list[0].brand)"
                            v-show="game_section.list.length>=20 & game_section.list.length%20 ==0"><span
                                class='Fvermas'>{{tr("SHOWM")}}</span>
                            <font-awesome-icon icon="fa-solid fa-angles-down" style="color: red; font-weight: bold" />
                        </span>
                    </div>
                </div>
            </section>
            <!--- FIN DE SECCIONES -->

            <section id="ys_secvirtual" class="" v-if="active_view=='virtual'">
                <p class="title-games">{{tr("GAMEV")}}</p>
                <div id="ys_showVIR" class="universal-row universal-no-gutters universal-position-relative">
                    <div class="column-mb-6 effect-padd" @click="launchGame('gr','GoldenRace','10100','mb')"
                        onclick="btncenter()">
                        <div class="game_inner game-sm"><img class="universal-img-fluid "
                                :src="`${ASSETS_URL}/casino/images/golden-race.png`" alt="">
                            <div class="new-text-div">
                                <p class="title-game">{{tr("GRACE")}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="column-mb-6 effect-padd" @click="launchGame('vg','SportRace','sportrace','mb')"
                        onclick="ysbtncenter()">
                        <div class="game_inner game-sm"><img class="universal-img-fluid "
                                :src="`${ASSETS_URL}/casino/images/universal-soft.png`" alt="">
                            <div class="new-text-div">
                                <p class="title-game">{{tr("USOFT")}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        
            <section id="ys_sectv" class="" v-if="active_view=='tv'">
                <p class="title-games">JUEGOS TV</p>
                <div id="ys_showTV" class="universal-row universal-no-gutters universal-position-relative">
                    <div class="column-mb-6 effect-padd" @click="launchGame('gr','HollywoodTv','100153','mb')"
                        onclick="btncenter()">
                        <div class="game_inner game-sm"><img class="universal-img-fluid "
                                :src="`${ASSETS_URL}/casino/images/hollywood.png`" alt="">
                            <div class="new-text-div">
                                <p class="title-game">{{tr("HOLL")}}</p>
                            </div>
                        </div>
                    </div>
        
                    <div class="column-mb-6 effect-padd" @click="launchGame('tv','tvBet','tvBet','mb')">
                        <div class="game_inner game-sm"><span class="game__badges"><span
                                    class="game__badge game__badge--new">New</span></span><img class="universal-img-fluid "
                                :src="`${ASSETS_URL}/casino/images/tvbet.png`" alt="">
                            <div class="new-text-div">
                                <p class="title-game">{{tr("TVBET")}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        
            <section v-if="active_view=='home'">
                <section id="ys_sechome" class="carousel__games" v-for="(item, i) in typeGames">
                    <div class="carousel__header" @click="setActiveView(item.code)">
                        <p class="carousel__title">{{tr(item.code)}}</p>
                        <a class="carousel__redirect" href="#">{{tr("SALL")}} <font-awesome-icon
                                icon="fas fa-chevron-right" /></a>
                    </div>
                    <div class="carousel__body">
                        <div class="carousel__item" v-for="(subitem, i) in item.listgame" @click="setActiveView(item.code)">
                            <div class="carousel__image"><img class="img-fluid" :src="subitem.image" :alt="subitem.name">
                                <div class="carousel__name">{{item.code =='sportbook'? tr(subitem.code) : subitem.name}}</div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
        <!-- LISTA DE CAPAS DE JUEGOS -->

        <!-- MENU DE APUESTAS Y MOVIMIENTOS -->
        <div style="display: none;" class="modal fade" id="u-user-perfil" tabindex="-1" role="dialog"
            aria-labelledby="userperfil" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="universal-modal-content">
                    <div class="universal-modal-body">
                        <div class="universal-row">
                            <div class="column-mb-6">
                                <p class="moda-p">Artigel # 1000074</p>
                            </div>
                            <div class="column-mb-6"><a class="modal-salir" href="">{{tr("EXIT")}}</a></div>
                        </div>
                        <hr class="mt-0">
                        <div class="universal-row">
                            <div class="column-mb-6">
                                <p class="moda-p">{{tr("BLCE")}}</p>
                            </div>
                            <div class="column-mb-6">
                                <div class="modal-money">
                                    <p class="moda-p mb-3">0,00</p>
                                    <div class="mb-3"><i class="fas fa-sync-alt"></i></div>
                                </div>
                            </div>
                        </div>
                        <hr class="mt-0">
                        <div class="universal-row">
                            <div class="column-mb-6">
                                <a class="modal-text moda-p" href="#" data-toggle="modal" data-target="#user-apuestas">
                                    <i class="fas fa-clipboard-check mb-3 mr-3"></i>
                                    <p class="mb-3">{{tr("BETS")}}</p>
                                </a>
                            </div>
                            <div class="column-mb-6">
                                <a class="modal-text moda-p" href="#" data-toggle="modal" data-target="#user-movimiento">
                                    <i class="fas fa-search-dollar mb-3 mr-3"></i>
                                    <p class="mb-3">{{tr("MOVE")}}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="universal-modal-footer">
                        <button type="button" class="btn btn-secondary btn-block" data-dismiss="modal">{{tr("EXI")}}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- FIN DE MENU DE APUESTAS Y MOVIMIENTOS -->

        <!-- LISTADO DE APUESTAS -->
        <div style="display: none;" class="modalTransactions" id="betListModal">
            <div class="universal-modal-fade" id="user-apuestas" tabindex="-1" role="dialog" aria-labelledby="userperfil"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="universal-modal-content" style="max-height:90vh; overflow-y:auto;">
                        <div class="no-border universal-modal-header-title">
                            <h5 class="universal-h5 Fmodalbets1">{{tr("EXI")}}</h5>
                        </div>
                        <div class="universal-modal-body">
                            <div class="datos-div">
                                <div class="universal-row">
                                    <div class="universal-col-lg-6-modal column-mb-12">
                                        <div class="form-group">
                                            <label class="Fmodalbets2" for="">{{tr("BETS2")}}</label>
                                            <select class="universal-form-control" v-model="myBetsFilter.game_id">
                                                <option value="" disabled selected>{{tr("SELEC")}}</option>
                                                <option v-for="game in myBetsFilter.games" :value="game.gameid">
                                                    {{game.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="universal-col-lg-6-modal column-mb-12">
                                        <div class="form-group">
                                            <label class="Fmodalbets3" for="">{{tr("RESUL")}}</label>
                                            <select id="inputResultado" class="universal-form-control"
                                                v-model="myBetsFilter.bet_result">
                                                <option value="" disabled selected>{{tr("SELEC")}}</option>
                                                <option value="bets_lost">{{tr("SGAN")}}</option>
                                                <option value="bets_win">{{tr("CGAN")}}</option>
                                                <option value="bets_refund">{{tr("CDEV")}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="universal-col-lg-6-modal column-mb-12">
                                        <div class="form-group">
                                            <label class="Fmodalbets4" for="">{{tr("SINCE")}}</label>
                                            <input type="date" class="universal-form-control" v-model="myBetsFilter.start">
                                        </div>
                                    </div>
                                    <div class="universal-col-lg-6-modal column-mb-12">
                                        <div class="form-group">
                                            <label class="Fmodalbets4" for="">{{tr("TILL")}}</label>
                                            <input type="date" class="universal-form-control" v-model="myBetsFilter.end">
                                        </div>
                                    </div>
                                    <div class="universal-col-lg-6-modal column-mb-12">
                                        <label class="Fmodalbets5" for="">{{tr("CNTR")}}:</label>
                                        <button class="btn-consult-bets Fmodalbets5"
                                            @click="getMyBets()">{{tr("CNTR")}}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="payment-div" id="payment-div-id">
                                <div class="mybets-grid" v-for="(bet, index) in mybets">
                                    <div class="mybets-tem2">
                                        <span class="pay-text Fmodalbets7">{{tr("NUM")}}</span>
                                        <span class="pay-text0 mybets-text" style="padding: 0px 10px">{{index + 1}}</span>
                                    </div>
                                    <div class="mybets-tem2">
                                        <span class="pay-text Fmodalbets8">{{tr("NUM")}}</span>
                                        <span class="pay-text2 mybets-text">
                                            {{bet.date}}
                                        </span>
                                    </div>
                                    <div class="mybets-tem2">
                                        <span class="pay-text Fmodalbets6">{{tr("GAM3")}}</span>
                                        <span class="pay-text2 mybets-text">{{bet.gamename}}</span>
                                    </div>
                                    <div class="mybets-tem3">
                                        <span class="pay-text Fmodalbets9">{{tr("BET3")}}</span>
                                        <span class="pay-text2 mybets-text">{{trCurr(user.currency,'symbol')}}
                                            {{bet.amount
                                            ?? '0.00'}}</span>
                                    </div>
                                    <div class="mybets-tem3">
                                        <span class="pay-text Fmodalbets10">{{tr("PREM")}}</span>
                                        <span class="pay-text2 mybets-text">{{trCurr(user.currency,'symbol')}}
                                            {{bet.win
                                            ??
                                            '0.00'}}</span>
                                    </div>
                                    <div class="mybets-tem3">
                                        <span class="pay-text Fmodalbets11">{{tr("DELV")}}</span>
                                        <span class="pay-text2 mybets-text">{{trCurr(user.currency,'symbol')}} {{bet.refund ?? '0.00'}}</span>
                                    </div>
                                </div>
                                <div v-show="mybets.message" class="mybets-tem3">
                                    <span class="pay-text Fmodalbets11">{{mybets.message}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="universal-modal-footer">
                            <button type="button" class="btn-close-mb-modal Fmodaluser13"
                                @click="showUserModal('betListModal')">{{tr("EXI")}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- MOVIMIENTOS DE CUENTA -->
        <div style="display: none;" class="modalTransactions" id="movListModal">
            <div class="universal-modal-fade" id="user-movimiento" tabindex="-1" role="dialog" aria-labelledby="userperfil"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div class="universal-modal-content" style="max-height:90vh; overflow-y:auto;">
                        <div class="no-border universal-modal-header-title">
                            <h5 class="universal-h5 Fmodalmovi1">{{tr("MDSC")}}</h5>
                        </div>
                        <div class="universal-modal-body">
                            <div class="datos-div">
                                <div class="universal-row">
                                    <div class="universal-col-lg-6-modal column-mb-12">
                                        <div class="form-group">
                                            <label class="Fmodalmovi2" for="">{{tr("SINCE")}}</label>
                                            <input type="date" max="" class="universal-form-control" id="movidesde"
                                                v-model="myBetsFilter.start" />
                                        </div>
                                    </div>
                                    <div class="universal-col-lg-6-modal column-mb-12">
                                        <div class="form-group">
                                            <label class="Fmodalmovi3" for="">{{tr("TILL")}}</label>
                                            <input type="date" max="" class="universal-form-control" id="movihasta"
                                                v-model="myBetsFilter.end" />
                                        </div>
                                    </div>
                                    <div class="universal-col-lg-6-modal column-mb-12">
                                        <div class="form-group">
                                            <label class="Fmodalmovi4" for="">{{tr("CONC")}}</label>
                                            <select id="movistate" class="universal-form-control"
                                                v-model="myBetsFilter.bet_result">
                                                <option value="" disabled selected>{{tr("CNTR")}}</option>
                                                <option value="all">{{tr("all")}}</option>
                                                <option value="bets_lost">{{tr("PER")}}</option>
                                                <option value="bets_win">{{tr("WINN")}}</option>
                                                <option value="bets_refund">{{tr("DEV1")}}</option>
                                                <option value="DEPOSITOS" disabled>{{tr("AMON")}}</option>
                                                <option value="RETIROS" disabled>{{tr("RETI")}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="universal-col-lg-6-modal column-mb-12">
                                        <label class="Fmodalmovi5" for="">{{tr("CNTR")}}:</label>
                                        <button class="btn-consult-bets Fmodalmovi5"
                                            @click="myMovements();">{{tr("CNTR")}}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="payment-div" id="movement-div-id">
                                <div class="mybets-grid" v-for="(movement, index) in movements">
                                    <div class="mybets-tem2">
                                        <span class="pay-text Fmodalbets7">{{tr("NUM")}}</span>
                                        <span class="pay-text0 mybets-text" style="padding: 0px 10px">{{index +
                                            1}}</span>
                                    </div>
                                    <div class="mybets-tem2">
                                        <span class="pay-text Fmodalbets8">{{tr("DATE")}}</span>
                                        <span class="pay-text2 mybets-text">
                                            {{movement.date}}
                                        </span>
                                    </div>
                                    <div class="mybets-tem2">
                                        <span class="pay-text Fmodalbets6">{{tr("GAM3")}}</span>
                                        <span class="pay-text2 mybets-text">{{movement.gamename}}</span>
                                    </div>
                                    <div class="mybets-tem3">
                                        <span class="pay-text Fmodalbets9">{{tr("TYPE")}}</span>
                                        <span class="pay-text2 mybets-text">{{movement.type}}</span>
                                    </div>
                                    <div class="mybets-tem3">
                                        <span class="pay-text Fmodalbets10">{{tr("MNTO")}}</span>
                                        <span class="pay-text2 mybets-text">{{movement.amount ?? '0.00'}}</span>
                                    </div>
                                </div>
                                <div v-show="movements.message" class="mybets-tem3">
                                    <span class="pay-text Fmodalbets11">{{movements.message}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="universal-modal-footer">
                            <button type="button" class="btn-close-mb-modal Fmodaluser13"
                                @click="showUserModal('movListModal')">{{tr("EXI")}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- FIN DE MOVIMIENTOS DE CUENTA -->


        <div id="iframeModal" class="modal-slot">
            <div id="body-slot" class="body-slot">
                <div v-if="orientation=='V'" id="winsSlider" class="right-back-bar no-desktop" :style="{background: clientColor}">
                    <div class="btn-close-modal" @click="closeIframe()">
                        <font-awesome-icon icon="fa-solid fa-angles-left" />
                    </div>
                    <div class="scrolling-limit">
                        <div class="scrolling">
                            <p>Life is too short to play a bad game.</p>
                        </div>
                    </div>
                    <div class="btn-close-modal" @click="closeIframe()">
                        <font-awesome-icon icon="fa-solid fa-xmark" />
                    </div>
                </div>
                <div v-if="orientation=='H'" id="iframeCloseButton">
                    <button id="iframeButton" class="btn btn-alert btn-programs modal-prog" style="margin-top: 1rem;"
                        @click="closeIframe()">
                        <font-awesome-icon icon="fa-solid fa-angles-left"
                            style="color: yellow;size: 43px;font-size: 33px;font-weight: bold;" />
                    </button>
                </div>
                <iframe id="iframe" frameborder="0" style="transform: scale(1);">
                </iframe>
            </div>
        </div>
        
        <section id="games-single" class="body-casino">
            <iframe id="single-game-iframe" class='my-iflame' frameborder="0"></iframe>
        </section> 

        <ScrollTop :scroll="scrollToTop" :show="goTopVisible"></ScrollTop>

    </div>
</div>