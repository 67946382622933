const dictionary={
  //CATEGORIES
  'home': { es: 'HOME', fr: 'HOME', en: 'HOME' },
  'slot': { es: 'CASINO', fr: 'CASINO', en: 'SLOT' },
  'slot_live': { es: 'CASINO EN VIVO', fr: 'CASINO EN DIRECT', en: 'LIVE CASINO' },
  'crash_game': { es: 'CRASH GAMES', fr: 'CRASH GAMES', en: 'CRASH GAMES' },
  'scratch': { es: 'RASPA Y GANA', fr: 'GRATTER ET GAGNER', en: 'SCRATCH AND WIN' },
  'hipica': { es: 'HIPICA', fr: 'ÉQUITATION', en: 'HORSE RACING' },
  'sportbook': { es: 'DEPORTES', fr: 'SPORTS', en: 'SPORTSBOOK' },
  'VIRT': { es: 'JUEGOS VIRTUALES', fr: 'JEUX VIRTUELS', en: 'VIRTUAL GAMES' },
  'virtual': { es: 'JUEGOS VIRTUALES', fr: 'JEUX VIRTUELS', en: 'VIRTUAL GAMES' },
  'esports': { es: 'ESPORTS', fr: 'ESPORTS', en: 'ESPORTS' },

  //SECTIONS
  'ALL': { es: 'TODOS LOS JUEGOS', fr: 'TOUS LES JEUX', en: 'ALL GAMES' },
  'all': { es: 'Todos', fr: 'Tout', en: 'All' },
  'TOP': { es: 'TOP GAMES', fr: 'MEILLEURS JEUX', en: 'TOP GAMES' },
  'BUSQ': { es: 'BÚSQUEDA', fr: 'RECHERCHE', en: 'SEARCH' },
  'POPU': { es: 'POPULARES', fr: 'POPULAIRE', en: 'POPULAR' },
  'NUEV': { es: 'JUEGOS NUEVOS', fr: 'NOUVEAUX JEUX', en: 'NEW GAMES' },
  'CLAS': { es: 'OTROS JUEGOS', fr: 'D´AUTRES JEUX', en: 'OTHER GAMES' },
  'JUME': { es: 'JUEGOS DE MESA', fr: 'JEUX DE SOCIÉTÉ', en: 'BOARD GAMES' },
  'ANIM': { es: 'ANIMALES', fr: 'LES ANIMAUX', en: 'ANIMALS' },
  'FAST': { es: 'CRASH GAMES', fr: 'CRASH GAMES', en: 'CRASH GAMES' },
  'ROUL': { es: 'Roulette', fr: 'Roulette', en: 'Roulette' },
  'BACC': { es: 'Baccarat', fr: 'Baccarat', en: 'Baccarat' },
  'BINGO': { es: 'BINGO', fr: 'BINGO', en: 'BINGO' },
  'BJACK': { es: 'Blackjack', fr: 'Blackjack', en: 'Blackjack' },
  'BJLIV': { es: 'BLACK JACK LIVE', fr: 'BLACK JACK EN DIRECT', en: 'LIVE BLACKJACK' },
  'CARDS': { es: 'CARTAS', fr: 'CARTES', en: 'CARDS' },
  'DEPO': { es: 'DEPORTES', fr: 'DES SPORTS', en: 'SPORTS' },
  'DICE': { es: 'Dados', fr: 'DÉS', en: 'DICE' },
  'DISC': { es: 'DISCOS', fr: 'DISQUES', en: 'DISCS' },
  'DROP': { es: 'DROP & WINS', fr: 'GAGNEZ ET GAGNEZ', en: 'DROP & WINS' },
  'EAST': { es: 'JUEGOS DE TEMPORADA', fr: 'JEUX SAISONNIERS', en: 'SEASONAL GAMES' },
  'HISTO': { es: 'HISTORIAS', fr: 'HISTOIRES', en: 'STORIES' },
  'TVLIV': { es: 'GAME SHOWS', fr: 'JEUX TÉLÉVISÉS', en: 'TV SHOWS' },
  //'JUME':{'es':'JUEGOS DE MESA'},
  'HOME': { es: 'HOME', fr: 'Tout', en: 'ALL' }, 
  //'HOME': {es:'HOME',fr:'Casino en direct'}, 
  'LOTTO': { es: 'LOTTERY', fr: 'LOTERIE', en: 'LOTTO' },
  'Lotto': { es: 'LOTERIA', fr: 'LOTERIE', en: 'LOTTO' },
  'lotto': { es: 'Loteria', fr: 'Loterie', en: 'LOTTO' },
  'MEGAW': { es: 'MEGAWAYS', fr: 'MEGAWAYS', en: 'MEGAWAYS' },
  'MBACC': { es: 'MULTI BACCARAT', fr: 'MULTI-BACCARAT', en: 'MULTI BACCARAT' },
  'MWLIV': { es: 'MEGAWHEEL', fr: 'Megawheel', en: 'MEGAWHEEL' },
  'mwliv': { es: 'Megawheel', fr: 'Megawheel', en: 'Megawheel' },
  'PERS': { es: 'PERSONAJES', fr: 'PERSONNAGES', en: 'CHARACTERS' },
  'POKER': { es: 'POKER', fr: 'POKER', en: 'POKER' },
  'PUZZ': { es: 'PUZZLE', fr: 'PUZZLE', en: 'PUZZLE' },
  'RAGA': { es: 'JUEGOS RASPA Y GANA', fr: 'JEUX DE GRATTAGE', en: 'SCRATCH AND WIN GAMES' },
  'RLIV': { es: 'RULETA EN VIVO', fr: 'ROULETTE EN DIRECT', en: 'LIVE ROULETTE' },
  'RULE': { es: 'RULETA', fr: 'ROULETTE', en: 'ROULETTE' },
  'FAVS': { es: 'Favoritos', fr: 'Favoris', en: 'Favorites' },
  'GSWS': { es: 'GameShows', fr: 'Jeux Télévisés', en: 'Game Shows' },
  'PROV': { es: 'Proveedores', fr: 'Fournisseurs', en: 'Providers' },
  'S103L': { es: 'SLOT 10Cx8L', fr: 'SLOT 10Cx8L', en: 'SLOT 10Cx8L' },
  'S4C3L': { es: 'SLOT 4Cx3L', fr: 'SLOT 4Cx3L', en: 'SLOT 4Cx3L' },
  'S4C4L': { es: 'SLOT 4Cx4L', fr: 'SLOT 4Cx4L', en: 'SLOT 4Cx4L' },
  'S5C3L': { es: 'SLOT 5Cx3L', fr: 'SLOT 5Cx3L', en: 'SLOT 5Cx3L' },
  'S5C4L': { es: 'SLOT 5Cx4L', fr: 'SLOT 5Cx4L', en: 'SLOT 5Cx4L' },
  'S5C5L': { es: 'SLOT 5Cx5L', fr: 'SLOT 5Cx5L', en: 'SLOT 5Cx5L' },
  'S5C6L': { es: 'SLOT 5Cx6L', fr: 'SLOT 5Cx6L', en: 'SLOT 5Cx6L' },
  'S5C7L': { es: 'SLOT 5Cx7L', fr: 'SLOT 5Cx7L', en: 'SLOT 5Cx7L' },
  'S6C3L': { es: 'SLOT 6Cx3L', fr: 'SLOT 6Cx3L', en: 'SLOT 6Cx3L' },
  'S6C4L': { es: 'SLOT 6Cx4L', fr: 'SLOT 6Cx4L', en: 'SLOT 6Cx4L' },
  'S6C5L': { es: 'SLOT 6Cx5L', fr: 'SLOT 6Cx5L', en: 'SLOT 6Cx5L' },
  'S6C6L': { es: 'SLOT 6Cx6L', fr: 'SLOT 6Cx6L', en: 'SLOT 6Cx6L' },
  'S6C7L': { es: 'SLOT 6Cx7L', fr: 'SLOT 6Cx7L', en: 'SLOT 6Cx7L' },
  'S7C7L': { es: 'SLOT 7Cx7L', fr: 'SLOT 7Cx7L', en: 'SLOT 7Cx7L' },
  'S8C8L': { es: 'SLOT 8Cx8L', fr: 'SLOT 8Cx8L', en: 'SLOT 8Cx8L' },
  'SICBO': { es: 'SICBO', fr: 'Sicbo', en: 'SICBO' },
  'TEMA': { es: 'TEMATICOS', fr: 'THÈMES', en: 'THEMES' },
  'NCLAS': { es: 'NO CLASIFICADO', fr: 'NON QUALIFIÉ', en: 'UNCLASSIFIED' },
  'INDI': { es: 'INDIAN GAMES', fr: 'JEUX INDIENS', en: 'INDIAN GAMES' },
  'OTLOB': { es: 'ONE TOUCH LOBBY', fr: 'LOBBY À UNE TOUCHE', en: 'ONE TOUCH LOBBY' },
  'ASIA': { es: 'ASIATICOS', fr: 'ASIATIQUES', en: 'ASIAN' },
  'STITLE': { es: 'ELIGE UN JUEGO Y EMPIEZA A GANAR!', fr: 'CHOISISSEZ UN JEU ET COMMENCEZ À GAGNER!', en: 'CHOOSE A GAME AND START WINNING!' },
  'SW100': { es: 'Ordenar por:', fr: 'Trier par:', en: 'Sort by:' },
  'SALL': { es: 'Ver Todos', fr: 'Voir Tous', en: 'See All' },
  'LESS': { es: 'Menor precio', fr: 'Prix inférieur', en: 'Lower Price' },
  'HIGHER': { es: 'Mayor precio', fr: 'prix plus élevé', en: 'Higher Price' },
  'DROPT': { es: 'Participa en el torneo de', fr: 'Participer au tournoi de', en: 'Participate in the tournament of' },
  'DROPT2': { es: 'Tragamonedas Drops&Wins', fr: 'Machines à sous Drops&Wins', en: 'Drops&Wins Slot Machines' },
  'DROPT3': {es: '¡ES HORA DE GANAR! PARTICIPA EN LOS JUEGOS', fr: 'IL EST TEMPS DE GAGNER! PARTICIPER AUX JEUX', en: 'ITs TIME TO WIN! PARTICIPATE IN THE GAMES'},
  'DROPT4': { es:'IDENTIFICADOS CON EL LOGO DROPS&WINS', fr:'IDENTIFIÉ AVEC LE LOGO DROPS & WINS', en:'IDENTIFIED WITH THE DROPS&WINS LOGO'},
  'DROPT5': { es: '¡Participa Ya!', fr: 'Participez maintenant!', en: 'Join Now!' },
  'RYGT1': { es: 'Ahora esta aquí', fr: 'maintenant c´est ici!', en: 'Now its here!'},
  'RYGT2': { es: 'juegos de Raspa y gana', fr: 'jeux à gratter', en: 'scratch and win games' },
  'RYGT3': { es: 'Podras ganar mucho dinero con nuestros juegos de raspa y gana', fr: 'Vous pouvez gagner beaucoup d´argent avec nos jeux de grattage', en: 'You can win a lot of money with our scratch and win games' },
  'RYGT4': { es: '¿Podras conseguir el jackpot que tanto soñastes?', fr: 'Arriverez-vous à décrocher le jackpot dont vous rêviez tant ?', en: 'Can you get the jackpot you dreamed of?' },
  'RYGT5': { es: 'No se encontraron juegos', fr: 'Aucun jeu trouvé', en: 'No games found' },
  'RYGT6': { es: 'NEW', fr: 'NEW', en: 'NEW' },
  'SHOWM': { es: 'Ver Más', fr: 'Voir plus', en: 'Show More' },
  'SHOWL': { es: 'Ver Menos', fr: 'Voir moins', en: 'Show Less' },
  'ALERT': { es: 'ATENCION!', fr: 'ATTENTION!', en: 'ATTENTION!' },
  'ALERT1': { es: 'Los juegos no se encuentran disponibles.', fr: 'Les jeux ne sont pas disponibles.', en: 'Games are not available.' },
  'GLIVE': { es: 'JUEGOS TV', fr: 'JEUX TÉLÉ', en: 'TV GAMES' },
  'HOLL': { es: 'Hollywood Tv', fr: 'Télévision hollywoodienne', en: 'Hollywood TV' },
  'TVBET': { es: 'TVBET', fr: 'TVBET', en: 'TVBET' },
  'DFPRV': { es: 'Elige tus', fr: 'Sports favoris', en: 'Choose your' },
  'DFAVS': { es: 'Deportes favoritos', fr: 'Sélectionnez vos', en: 'Favorite Sports' },
  'DTITLE': { es: 'y apuesta ahora', fr: 'et pariez maintenant', en: 'and bet now' },
  'GAMEV': { es: 'JUEGOS VIRTUALES', fr: 'JEUX VIRTUELS', en: 'VIRTUAL GAMES' },
  'GRACE': { es: 'Golden Race', fr: 'Course en or', en: 'Golden Race' },
  'USOFT': { es: 'Universal Soft', fr: 'Universel Doux', en: 'Universal Soft' },
  'PUP': { es: 'POP UP', fr: 'APPARAITRE', en: 'POP UP' },
  'OSITE': { es: 'ON SITE', fr: 'SUR SITE', en: 'ON SITE' },
  //
  'BETS': { es: 'Mis Apuestas', fr: 'Mes Paris', en: 'My Bets' },
  'MOVE': { es: 'Movimientos', fr: 'Mouvements', en: 'Movements' },
  'CLOSE': { es: 'Cerrar Menu', fr: 'Fermer le menu', en: 'Close Menu' },
  'EXIT': { es: 'Cerrar Sesion', fr: 'Fermer la session', en: 'Logout' },
  'EXI': { es: 'Cerrar', fr: 'Fermer', en: 'Close' },
  'BLCE': { es: 'Saldo:', fr: 'Solde:', en: 'Balance:' },
  'BPLACE': { es: 'APUESTAS REALIZADAS', fr: 'PARIS PLACES', en: 'PLACED BETS' },
  'BETS2': { es: 'Juegos', fr: 'Jeux', en: 'Games' },
  'SELEC': { es: 'Seleccionar', fr: 'Sélectionner', en: 'Select' },
  'RESUL': { es: 'Resultado:', fr: 'Résultat:', en: 'Result:' },
  'SGAN': { es: 'Sin ganancia', fr: 'Aucun de profit', en: 'No Win' },
  'CGAN': { es: 'Con ganancia', fr: 'Avec profit', en: 'With Win' },
  'CDEV': { es: 'Con Devolución', fr: 'Avec retour', en: 'With Return' },
  'SINCE': { es: 'Desde:', fr: 'Depuis:', en: 'Since:' },
  'SEARCH': { es: 'Buscar Juegos', fr: 'Rechercher partie', en: 'Search Games' },
  'TILL': { es: 'Hasta:', fr: 'Jusqu´à:', en: 'Until:' },
  'CNTR': { es: 'Consultar', fr: 'Consulter', en: 'Check' },
  'NUM': { es: 'Número', fr: 'Le numéro', en: 'Number' },
  'DATE': { es: 'Fecha Creado', fr: 'Date Créée', en: 'Created Date' },
  'GAM3': { es: 'Juego', fr: 'Jouer', en: 'Game' },
  'BET3': { es: 'Apuestas', fr: 'Paris', en: 'Bets' },
  'PREM': { es: 'Premio', fr: 'Récompense', en: 'Prize' },
  'DEVL': { es: 'Devolución', fr: 'Revenir', en: 'Return' },
  'MDSC': { es: 'MOVIMIENTOS DE SU CUENTA', fr: 'MOUVEMENTS DE VOTRE COMPTE', en: 'MOVEMENTS OF YOUR ACCOUNT' },
  'CONC': { es: 'Concepto:', fr: 'Concept:', en: 'Concept:' },
  'PER': { es: 'PERDIDAS', fr: 'PERTES', en: 'LOSSES' },
  'WINN': { es: 'GANADAS', fr: 'A GAGNÉ', en: 'WINS' },
  'DEV1': { es: 'DEVOLUCIONES', fr: 'RETOUR', en: 'RETURNS' },
  'AMON': { es: 'DEPOSITOS', fr: 'DÉPÔTS', en: 'DEPOSITS' },
  'RETI': { es: 'RETIROS', fr: 'RETRAITS', en: 'WITHDRAWALS' },
  'TYPE': { es: 'Tipo', fr: 'Type', en: 'Type' },
  'MNTO': { es: 'Monto', fr: 'Montant', en: 'Amount' },
  'live': { es: 'En Vivo', fr: 'En Direct', en: 'Live' },
  'soccer': { es: 'Fútbol', fr: 'Football', en: 'Soccer' },
  'futbol': { es: 'Fútbol', fr: 'Football', en: 'Soccer' },
  'basket': { es: 'Basketball', fr: 'Basketball', en: 'Basketball' },
  'boxing': { es: 'Boxing', fr: 'Boxing', en: 'Boxing' },
  'baseball': { es: 'Baseball', fr: 'Baseball', en: 'Baseball' },
  'tenis': { es: 'Tenis', fr: 'Tennis', en: 'Tennis' },
  'goleadores': { es: 'Goleadores', fr: 'Buteurs', en: 'Top Scorers' },
  'especiales': { es: 'Especiales', fr: 'Spéciaux', en: 'Specials' }
}; 
const currencies={
  PEN: {symbol:'S/', name:'Nuevo Sol Peruano'},
  USD: {symbol:'$', name:'Dólar Estadounidense'},
  CLP: {symbol:'$', name:'Peso Chileno'},
  ARS: {symbol:'$', name:'Peso Argentino'},
  BRL: {symbol:'R$', name:'Real Brasileño'},	
  TND: {symbol:'₣', name:'Dinar Tunecino'},
  COP: {symbol:'$', name:'Peso Colombiano'},
  MXN: {symbol:'$', name:'Peso Mexicano'}	  
};
export default {
  tr(code, lang){
    try {
      let text = dictionary[code][lang];
      return text;

    } catch (error) {
      return "Undefined text";
    }
  },
  trCurr(code,prop){
    if( !currencies[code] ) return "N_C_"+code;
    else if( !currencies[code][prop] ) return "N_L_"+code;
    return currencies[code][prop];
  },
sleep(seconds){ return new Promise((result) =>{ setTimeout(()=>{  result() }, seconds*1000); }); }


};