<div class=''>
    <link rel="icon" type="image/x-icon" th:href="@{|${ASSETS_URL}/casino/images/favicon.ico|}" />
    <!--- LLAMADAS CSS -->
    <div style="display: flex; justify-content: center; align-items: center;
      height: 100vh; overflow: hidden;" v-if="preloader">
        <div v-if="preloader" :class="pagetheme == 'white' ? 'lds-ripple-purple' : 'lds-ripple-white'">
            <div></div>
            <div></div>
        </div>
    </div>
    <div style="width: 100%" v-show="!preloader">
        <div v-show="showMenu==1" class="sub-header">
            <a v-for="cat in CATEGORIES" :id="cat.code+'-u-category-menu'" role="button" href="javascript:void(0);" class="header-btn"
                :class="(active_view == cat.code ? 'active' : ''),(cat.isnew ? 'lazo-new':'')"
                @click="showGames(cat.code)" v-cloak>{{ tr(cat.code) }}</a>
        </div>
        <!--- SECCION DE SLIDER -->
        <div v-if="active_view=='home'" v-cloak>
            <SlotSwipper :slides="homeSlides" :navigation="true" :pagination="{ clickable: true }" :onClick="onBannerClick" />
        </div>
        <div v-if="active_view=='slot'" v-cloak>
            <SlotSwipper :slides="slotSlides" :navigation="true" :pagination="{ clickable: true }" :onClick="onBannerClick" />
        </div>
        <div v-if="active_view=='slot_live'" v-cloak>
            <SlotSwipper :slides="slotliveSlides" :navigation="true" :pagination="{ clickable: true }" :onClick="onBannerClick" />
        </div>
        <div v-if="active_view=='scratch'" v-cloak>
            <SlotSwipper :slides="scratchSlides"  :onClick="onBannerClick" />
        </div>
        <div v-if="active_view=='virtual'" v-cloak>
            <SlotSwipper :slides="virtualSlides" :onClick="onBannerClick" />
        </div>
        <div v-if="active_view=='crash_game'" v-cloak>
            <SlotSwipper :slides="crashSlides" :onClick="onBannerClick" />
        </div>
        <!--<div v-show="active_view=='tv'" v-cloak>
            <swiper :slides-per-view="1" :space-between="50" @swiper="onSwiper" @slideChange="onSlideChange">
                <swiper-slide><img class="img-fluid-class" :src="`${ASSETS_URL}/casino/images/banner/bannerv4.png`" />
                </swiper-slide>
            </swiper>
        </div>-->
        <!--- FIN DE IMAGEN DE SLIDER -->
        <!--- FILTROS ====================================== -->
        <div class="jackpot-bg" v-if="active_view=='slot'" v-cloak>
            <div id="jackpotdiv1" class="jackpot-div">
                <div class="search-div search-casino">
                    <input id="searchcasino" class="slot-input-search univ-form-control" type="search" :placeholder="tr('SEARCH')" autocomplete="off" readonly 
                    onfocus="this.removeAttribute('readonly');"
                        aria-label="Search" v-model="text_search" @keyup="search('slot')" /><button
                        class="slot-btn-search btn-search" @click="search('slot')">
                        <font-awesome-icon icon="fas fa-search" />
                    </button>
                </div>
                <div class="jackpot-list-2 Universaldropdown">
                    <span id="ys_showtipos" @click="showDropdowns('typeDrop')">
                        <a class="dropbtn" style="display: flex;
                        justify-content: space-between;
                        align-items: center;">
                            <div class="dropbtn">
                                {{tr(currentCategoryFilter)}}
                            </div>
                            <font-awesome-icon class="dropbtn" style="margin-right: 10px;" icon="fas fa-caret-down" />
                        </a>

                        <div id="typeDrop" class="drop-casino scroll-provee Universaldropdown-content">
                            <a class="Universaldropdown-item Fcasino3" href="javascript:void(0)"
                                @click="currentCategoryFilter = 'ALL';listAllGames()">{{tr("ALL")}}</a>
                            <a class="Universaldropdown-item Fcasino3" href="javascript:void(0)"
                                v-for="item in SLOT_GAME_SECTIONS.filter(e=>{ return e.showInFilter && e.views.find(c => c == active_view)})"
                                @click="currentCategoryFilter = item.code; onGametypeChange(item.code)"><span>{{tr(item.code)}}</span><span>({{item.list.length}})</span></a>
                        </div>
                    </span>
                </div>

                <div class="jackpot-list-2 Universaldropdown">
                    <span id="ys_showprov" @click="showDropdowns('provDrop')">
                        <a class="dropbtn" style="display: flex;
                        justify-content: space-between;
                        align-items: center;">
                            <div class="dropbtn">
                                {{tr("PROV")}}
                            </div>
                            <font-awesome-icon class="dropbtn" style="margin-right: 10px;" icon="fas fa-caret-down" />
                        </a>

                        <div id="provDrop" class="drop-casino scroll-provee Universaldropdown-content">
                            <a style="display: flex; justify-content: space-between; align-items: center;" class="Universaldropdown-item" href="javascript:void(0)"
                                :class="{ active: proveedor_select == brand.code}"
                                @click="onBrandChange('slot',brand.code)"
                                v-for="brand in brands"><span>{{brand.name}}</span><span> &nbsp
                                    ({{brand.total}})</span></a>
                        </div>
                    </span>
                </div>

                <div class="jackpot-list-2">
                    <a role="button" href="javascript:void(0);" class="Fcasino10" @click="listFavs()">{{tr("FAVS")}}</a>
                    <i class="icon-heart">
                        <font-awesome-icon icon="far fa-heart fas" />
                    </i>
                </div>
            </div>
        </div>
        <!-- ----- SLOT LIVE-->
        <div class="jackpot-bg" v-if="active_view=='slot_live'" v-cloak>
            <div id="jackpotdiv2" class="jackpot-div">
                <div class="jackpot-list-3">
                    <div class="filter-icons filter-icons-all" :class="activeSlotLiveSection == null ? 'active': ''"
                        data-filter="all-online">
                        <i style="font-size: 1.9rem" @click="showGames('slot_live');activeSlotLiveSection=null;">
                            <font-awesome-icon icon="fa-solid fa-coins" />
                        </i><small  class="filter-small Ffilter1">{{tr("all")}}</small>
                    </div>
                    <div class="filter-icons" :class="activeSlotLiveSection == 'RLIV' ? 'active': ''"
                        data-online="onlineRLIV" @click="onGametypeChange('RLIV'); activeSlotLiveSection='RLIV';">
                        <i class="far"><img img="img-fluid-class"
                                :src="`${ASSETS_URL}/us_img_slot_live/RULETA VIVO.png`" /></i>
                        <small class="filter-small Ffilter2">{{tr("ROUL")}}</small>
                    </div>
                    <div class="filter-icons" :class="activeSlotLiveSection == 'BJLIV' ? 'active': ''"
                        data-online="onlineBJLIV" @click="onGametypeChange('BJLIV'); activeSlotLiveSection='BJLIV';">
                        <i class="far"><img img="img-fluid-class"
                                :src="`${ASSETS_URL}/us_img_slot_live/BJ LIVE.png`" /></i><small
                            class="filter-small Ffilter3">{{tr("BJACK")}}</small>
                    </div>
                    <div class="filter-icons" :class="activeSlotLiveSection == 'BACC' ? 'active': ''"
                        data-online="onlineBACC" @click="onGametypeChange('BACC'); activeSlotLiveSection='BACC';">
                        <i class="far"><img img="img-fluid-class"
                                :src="`${ASSETS_URL}/us_img_slot_live/BACCARAT.png`" /></i><small
                            class="filter-small Ffilter4">{{tr("BACC")}}</small>
                    </div>
                    <div class="filter-icons" :class="activeSlotLiveSection == 'MWLIV' ? 'active': ''"
                        data-online="onlineMWLIV" @click="onGametypeChange('MWLIV'); activeSlotLiveSection='MWLIV';">
                        <i class="far"><img img="img-fluid-class"
                                :src="`${ASSETS_URL}/us_img_slot_live/MEGAWHEEL.png`" /></i><small
                            class="filter-small Ffilter5">{{tr("MWLIV")}}</small>
                    </div>
                    <div class="filter-icons" :class="activeSlotLiveSection == 'LOTTO' ? 'active': ''"
                        data-online="onlineLOTTO" @click="onGametypeChange('LOTTO'); activeSlotLiveSection='LOTTO';">
                        <i class="far"><img img="img-fluid-class"
                                :src="`${ASSETS_URL}/us_img_slot_live/LOTTERY.png`" /></i><small
                            class="filter-small Ffilter6">{{tr("lotto")}}</small>
                    </div>
                    <div class="filter-icons" :class="activeSlotLiveSection == 'SICBO' ? 'active': ''"
                        data-online="onlineSICBO" @click="onGametypeChange('SICBO'); activeSlotLiveSection='SICBO';">
                        <i class="far"><img img="img-fluid-class"
                                :src="`${ASSETS_URL}/us_img_slot_live/SICBO.png`" /></i><small
                            class="filter-small Ffilter7">{{tr("SICBO")}}</small>
                    </div>
                    <div class="filter-icons" :class="activeSlotLiveSection == 'TVLIV' ? 'active': ''"
						data-online="onlineSICBO" @click="onGametypeChange('TVLIV'); activeSlotLiveSection='TVLIV';">
                        <i class="far"><img	img="img-fluid" 
                                :src="`${ASSETS_URL}/us_img_slot_live/TV.png`"></i><small
							class="filter-small Ffilter7">{{tr("GSWS")}}</small></div>
                </div>
                <!--- LISTA DE FAVORITOS -->
                <div class="jackpot-list-2">
                    <a role="button" href="javascript:void(0);" class="Fcasino10" @click="listFavs()">{{tr("FAVS")}}</a><i
                        class="icon-heart">
                        <font-awesome-icon icon="far fa-heart fas" />
                    </i>
                </div>
                <!--- FIN DE LISTA DE FAVORITOS -->
                <!--- COMBO DE PROVEEDORES -->


                <div class="jackpot-list-2 Universaldropdown">
                    <span id="ys_showprov" >
                        <a class="dropbtn" href="javascript:void(0);" @click="showDropdowns('provDrop')" style="display: flex;
                        justify-content: space-between;
                        align-items: center;">
                            <div class="dropbtn">
                                {{tr("PROV")}}
                            </div>
                            <font-awesome-icon style="margin-right: 10px;" icon="fas fa-caret-down" />
                        </a>

                        <div id="provDrop" class="drop-casino scroll-provee Universaldropdown-content">
                            <a style="display: flex; justify-content: space-between; align-items: center;" class="Universaldropdown-item" href="javascript:void(0)"
                                :class="{ active: proveedor_select == brand.code}"
                                @click="onBrandChange('slot_live',brand.code)"
                                v-for="brand in slotlive_brands"><span>{{brand.name}}</span><span> &nbsp
                                    ({{brand.total}})</span></a>
                        </div>
                    </span>
                </div>

                <!--- FIN DE COMBO DE PROVEEDORES -->
                <div class="position-relative" style="display: flex">
                    <div class="jackpot-list-search">
                        <i @click="slot_live_search('opmodal')">
                            <font-awesome-icon icon="fas fa-search" />
                        </i>
                    </div>
                    <div class="formsearch" id="opinput" name="opinput">
                        <input id="searchcasino2" class="universal-form-control" type="search" :placeholder="tr('SEARCH')" autocomplete="off" readonly 
                        onfocus="this.removeAttribute('readonly');"
                            aria-label="Search" v-model="text_search" @keyup="slot_live_search('search')"
                            @keydown.esc="slot_live_search('clmodal')" /><button id="cerrarsearch"
                            @click="slot_live_search('clmodal')" class="universal-btn btn-outline-danger btn-search2"
                            type="submit">
                            <i>
                                <font-awesome-icon icon="fa-solid fa-xmark" />
                            </i>
                        </button>
                    </div>
                </div>
            </div>
            <!--- FIN DE BARRA DE CASINO EN VIVO -->
        </div>

        <!-- ----- -->
        <div class="jackpot-bg" v-if="active_view=='scratch'" v-cloak>
            <div id="jackpotdiv3" class="jackpot-div">
                <p class="jackpot-list-title">{{tr("STITLE")}}</p>
                <div class="jackpot-list-select">
                    <span class="w-100">{{tr("SW100")}}</span>
                    <select class="form-control" @change="orderBy($event.target.value)">
                        <option value="scratch" selected>{{tr("SALL")}}</option>
                        <option value="less">{{tr("LESS")}}</option>
                        <option value="higher">{{tr("HIGHER")}}</option>
                    </select>
                </div>
            </div>
        </div>

        <!-- ------------------------==================================------------- -->
        <!--- SECCIONES SUPERIORES -->
        <section :id="'ys_sec'+game_section.code" class="body-casino"
            v-for="game_section in SLOT_GAME_SECTIONS.filter(e=>e.shown)"
            v-show="game_section.views.includes(active_view)" v-cloak>
            <div class="banner_section principal" v-if="game_section.code=='DROP'">
                <div class="wrapper">
                    <img class="custom_banner_bg" :src=slotMidSlides />
                    <div class="container-fluid">
                        <div class="banner_content">
                            <div class="text_holder column-6">
                                <div class="text_container">
                                    <h2 class="title wow fadeInLeft">
                                        {{tr("DROPT")}}<br />{{tr("DROPT2")}}
                                    </h2>
                                    <p class="text_yellow wow fadeInLeft delayX0"
                                        style="color: white; font-weight: bold">
                                        {{tr("DROPT3")}}
                                    </p>
                                    <p class="text_gray wow fadeInLeft delayX0" style="color: white; font-weight: bold">
                                        {{tr("DROPT4")}}
                                    </p>
                                    <div class="btn_holder wow fadeIn delayX1">
                                        <h3>
                                            <a href="#" class="cta_btn" onclick="gotoSection('DROP')">{{tr("DROPT5")}}</a>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="img_container column-6">
                                <!-- <img alt="Front Image" class="element wow fadeInRight" src="<%=ASSETS_URL%>casino/images/singleimg.png"> -->
                                <img alt="Front Image" class="element wow fadeInRight" style="visibility: hidden"
                                    :src="`${ASSETS_URL}/casino/images/dropswin.png`" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="banner_section raspaygana"
                v-if="active_view =='scratch' & game_section.code=='CLAS' & !game_section.all">
                <div class="wrapper">
                    <img class="custom_banner_bg_sc" :src=scratchMidSlides />
                </div>
            </div>

            <div class="row no-gutters">
                <div class="col-12">
                    <p class="title-casino" :class="'Ftitle'+game_section.code">
                        {{game_section.code =='BRAND' ? game_section.name :tr(game_section.code)}}
                    </p>
                </div>
            </div>
            <div v-if="!game_section.list.length && !sectionLoader" style="color: white">
            {{tr("RYGT5")}}
            </div>
            <div v-if="sectionLoader && !game_section.list.length"
                :class="pagetheme == 'white' ? 'lds-ripple-purple' : 'lds-ripple-white'">
                <div></div>
                <div></div>
            </div>
            <div :id='"gamesection-"+game_section.code' :ref='"gamesection-"+game_section.code' class="game-list">
                <div class="col-item sm filter-all-online" v-for="game in game_section.list">
                    <div class="row no-gutters">
                        <div class="col-12 effect-padd2">
                            <div class="effect-padd">
                                <div class="lazo-maxbet" v-if="game_section.code=='NUEV' || game.isNew==1">
                                    {{tr("RYGT6")}}
                                </div>
                                <div class="lazo-maxbet" v-if="active_view=='scratch' && game.min_bet > 0 ">
                                    {{trCurr(user.currency,'symbol')}} {{game.min_bet.toFixed(2)}}
                                </div>
                                <div class="game_inner game-sm my-effect">
                                    <div class="lazo-jackpot" v-if="active_view=='scratch' && game.jackpot > 0 ">
                                        {{trCurr(user.currency,'symbol')}} {{game.jackpot}}
                                    </div>
                                    <img class="img-fluid-class" style="width: 100%" :src="buildIMGURL(game)"
                                        onerror="this.src='https://d2zzz5z45zl95g.cloudfront.net/us_img_games/_no_game1.png'" />
                                    <div class="fav_holder">
                                        <div class="like-game">
                                            <i class="" v-show="!game.isfav" aria-hidden="true"
                                                @click="saveFavs(game, 'add')">
                                                <font-awesome-icon icon="far fa-heart" />
                                            </i>
                                            <i class="active" v-show="game.isfav" aria-hidden="true"
                                                @click="saveFavs(game, 'remove')">
                                                <font-awesome-icon icon="far fa-heart fas" />
                                            </i>
                                            <span id="f-vswaysbufking" class="span-casino">{{game.clicks}}</span>
                                        </div>
                                    </div>
                                    <div class="game_overlay">
                                        <div class="play">
                                            <div class="circle-play"></div>
                                            <!-- URL DEL JUEGO -->
                                            <a href="javascript:void(0)" style="font-size: 20px"
                                                @click="launchGame(game.provider, game.brand, game.gameid, game.mode, game.id)">
                                                <font-awesome-icon icon="fa-solid fa-play" />
                                            </a>
                                            <!-- FIN DE URL DEL JUEGO -->
                                        </div>
                                        <div class="game_title">
                                            <img class="img-fluid-class"
                                                :src="ASSETS_URL+'/us_img_brands/'+game.brand+'.png'" />
                                        </div>
                                    </div>
                                </div>
                                <div class="new-text-div" :title="game.name">
                                    <p class="title-game">{{game.name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mostrar-div">
                    <span class="btn-mostrar" @click="viewMore(game_section.code,game_section.list[0].brand)"
                        v-show="game_section.list.length>=20 && game_section.list.length%20 == 0"><span
                            class="Fvermas">{{tr("SHOWM")}}</span>
                        <font-awesome-icon icon="fa-solid fa-angles-down" style="color: red; font-weight: bold" />
                    </span>
                    <span class="btn-mostrar" @click="viewLess(game_section.code,'remove')"
                        v-show="game_section.list.length>=40"><span class="Fvermenos">{{tr("SHOWL")}}</span>
                        <font-awesome-icon icon="fa-solid fa-angles-up" style="color: red; font-weight: bold" />
                    </span>
                </div>
            </div>
        </section>
        <!--- FIN DE SECCIONES SUPERIORES -->
        <!-- MODAL -->
        <div id="iframeModal" class="modal-slot">
            <div class="body-slot">
                <button class="btn btn-alert btn-programs modal-prog" @click="closeIframe()">
                    <!--<i class="fas fa-times" aria-hidden="true"></i>-->
                    <font-awesome-icon icon="fa-solid fa-angles-left" style="
              color: yellow;
              size: 43px;
              font-size: 33px;
              font-weight: bold;
            " />
                </button>
                <iframe src="" id="iframe" frameborder="0" 
                style=" flex: 1 1 0%; width: 100%; height: 100%; background-color: black; transform: scale(1); "
          >
                </iframe>
            </div>
        </div>
        <!-- FIN DE MODAL -->

        <!-- ALERT -->
        <div class="alertmax" style="
        display: none;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 1;
      ">
            <div class="alert alert-danger alert-dismissible">
                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                    X
                </button>
                <h5><i class="icon fas fa-ban"></i>{{tr("ALERT")}}</h5>
                {{tr("ALERT1")}}
            </div>
        </div>
        <!-- FIN DE ALERT -->
        <a v-show="goTopVisible" class="scroll-top" href="javascript:void(0)" @click="scrollToTop"><i>
                <font-awesome-icon icon="fa-solid fa-chevron-up" />
            </i></a>

        <!-- TV GAMES VIEW -->
        <section id="ys_sectv" class="home-bg pl-0 pt-0 pr-0" v-if="active_view=='tv'" v-cloak>
            <p class="title-casino">{{tr("GLIVE")}}</p>
            <div id="ys_showTV" class="row no-gutters position-relative justify-content-center">
                <div class="col-4 effect-padd2" @click="launchGame('gr','HollywoodTv','100153','wb')"
                    onclick="btncenter()">
                    <div class="effect-padd">
                        <div class="game_inner my-effect">
                            <img class="img-fluid-class w-100" :src="`${ASSETS_URL}/casino/images/tv1.png`" alt=""
                                style="border: 4px solid transparent" />
                            <div class="game_overlay">
                                <div class="play">
                                    <div class="circle-play"></div>
                                    <!-- URL DEL JUEGO -->
                                    <a href="javascript:void(0)" style="font-size: 20px">
                                        <font-awesome-icon icon="fa-solid fa-play" />
                                    </a>
                                    <!-- FIN DE URL DEL JUEGO -->
                                </div>
                            </div>
                        </div>
                        <div class="new-text-div">
                            <p class="title-game">{{tr("HOLL")}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-4 effect-padd2" @click="launchGame('tv','tvBet','tvbet','wb')">
                    <div class="effect-padd">
                        <div class="game_inner my-effect">
                            <span class="game__badges">
                                <span class="game__badge game__badge--new">{{tr("RYGT5")}}</span>
                            </span>
                            <img class="img-fluid-class w-100" :src="`${ASSETS_URL}/casino/images/tv2.png`"
                                style="border: 4px solid transparent" />
                            <div class="game_overlay">
                                <div class="play">
                                    <div class="circle-play"></div>
                                    <!-- URL DEL JUEGO -->
                                    <a href="javascript:void(0)" style="font-size: 20px">
                                        <font-awesome-icon icon="fa-solid fa-play" />
                                    </a>
                                    <!-- FIN DE URL DEL JUEGO -->
                                </div>
                            </div>
                        </div>
                        <div class="new-text-div">
                            <p class="title-game">{{tr("TVBET")}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- HOME VIEW -->
        <section id="ys_sectv" class="home-bg" v-if="active_view=='home'" v-cloak>
            <div class="mySwiper-typegames">
                <SlotSwipper :slides="homeCategorySlides" :spaceBetween="10" loop="true" :slidesPerView="4" slidesPerGroup="3.5" loopFillGroupWithBlank="true" :navigation="false"  :onClick="onBannerClick" />
            </div>
            <div class="carousel__sports">
                <div class="carousel__sports--text">
                    <p>{{tr('DFAVS')}} <br> <strong class="main__color">{{tr("DFPRV")}}</strong> <br>{{tr("DTITLE")}}</p>
                </div>
                <SlotSwipper :slides="homeSportsIcons" :slidesPerView="3" showNames="true" slidesPerGroup="3" loop="true" :lang="lang" loopFillGroupWithBlank="true" :navigation="true" :onClick="onBannerClick" />
            </div>
        </section>
        
        <div id="pld" v-show="singleGamePreloader">
            <div  class="single-ring">
                <img src="../../../../public/preload.png" alt="preload">
                <span class="circ"></span>
            </div>
        </div>

        <!--- SECCION DE JUEGOS SIMPLES -->
        <section id="games-single" class="body-casino pt-0" v-show="is_single_game_view" style="height: 100%; position: relative">
            <iframe id="single-game-iframe" class="my-iflame" frameborder="0"></iframe>
        </section>
        <!--- FIN DE SECCION DE JUEGOS SIMPLES 	-->
    </div>
</div>