<template>
  <div>
    <ClassicPageMobile  v-if="isMobile()" />
    <ClassicPageDesktop v-else />
  </div>
</template>
<script>
import ClassicPageDesktop from "./components/classic/desktop/ClassicPageDesktop.vue";
import ClassicPageMobile from "./components/classic/mobile/ClassicPageMobile.vue";
document.body.className = "casino-bg";
if (
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
) {
  document.body.onresize = "get_altura();";
  document.body.onload = "get_altura();";
}
export default {
  name: "App",
  data(){
    return{
      active_view:'slot',
      
    }
  },
  methods: {
    isMobile() {
      let mobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
      return mobile;
    },
    gotoSection:(section)=>{
        const domSection = document.getElementById(section+'-u-category-menu')
        domSection && domSection.click() 
    }
    /*setView(view){
      this.active_view=view
    }*/
  },
  components: {
    ClassicPageDesktop,
    ClassicPageMobile,
  },
};
</script>
<style>
*,
::after,
::before {
  box-sizing: border-box;
}
body {
  display: block;
  margin: 0;
  font-family: arial, sans-serif;
}
.casino-bg {
  background: url("https://d2zzz5z45zl95g.cloudfront.net/casino/images/casino/fondo-casino.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
}

.universal-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  border-color: #dc3545;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.dropbtn {
  background-color: transparent;
  color: rgb(252, 246, 246);
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.Universaldropdown {
  position: relative;
  display: inline-block;
}

.Universaldropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.Universaldropdown-content a {
  color: black;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  display: block;
}

.Universaldropdown-item:hover {
  background-color: rgb(216, 38, 38);
}

.show {
  display: block;
}

.column-6 {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.swiper-button-next, .swiper-button-prev {
  color: var(--color-base);
}
.swiper-pagination-bullet {
  background: #fff;
}
.swiper-pagination-bullet-active {
  background: var(--color-base) ;
}

/*MOBILE*/
.column-mb-6 {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0;
  padding-left: 0;
}

.column-mb-6-search-form {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0;
  padding-left: 0;
}

.column-mb-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.universal-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.universal-no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.universal-position-relative {
  position: relative !important;
}

.universal-img-fluid {
  max-width: 100%;
  height: auto;
}

img {
  vertical-align: middle;
  border-style: none;
}

.universal-dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  margin: 0.6rem;
  border-bottom: 1px solid #dddddd;
}

.universal-dropdown-item.active,
.universal-dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ed3234;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

.universal-content-flex {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  padding-right: 0;
  padding-left: 0;
}

.universal-form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  /*color: #fff;*/
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  /*border-radius: 0.25rem;*/
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* user  options modal */
.universal-navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.universal-navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

/* Mis apuestas modal */
.modalTransactions {
  background: rgba(0, 0, 0, 0.338);
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  transition: opacity 0.5s linear;
  z-index: 10000;
}
.universal-modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.universal-h5 {
  font-size: 1.25rem;
  margin-bottom: 0.9rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
  margin-top: 0.9rem;
}

.universal-modal-fade {
  margin-top: 4rem;
}

.universal-modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.universal-modal-header-title {
  background: #3d3d3d;
  color: white;
  text-transform: uppercase;
  text-align: center;
}

.universal-modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.universal-col-lg-6 {
  padding-right: 15px;
  padding-left: 15px;
}

.universal-col-lg-6-modal {
  padding-right: 15px;
  padding-left: 15px;
}

.btn-consult-bets {
  cursor: pointer;
  margin-bottom: 1rem !important;
  display: block;
  width: 100%;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-;
}

.btn-close-mb-modal {
  color: #fff;
  background-color: red !important;
  border-color: red !important;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/*HOME SLIDES STYLES */
:root {
    --color-text: #fff;
    --color-base: #f54b00;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}
p{
  margin:0;
}


.carousel__games {
  padding: 0.5rem;
  color: #ffff;
}

.carousel__header {
  text-transform: uppercase;
  padding: 0.25rem 0;
  justify-content: space-between;
  display: flex;
  align-items: center;  
}
.carousel__body {
  display: flex;
  overflow-x: auto;
}

.carousel__title {
  font-size: 1.2rem;
  font-weight: 600;
  
}

.carousel__item {
  border: 1px solid #fff;
  margin-right: 0.5rem;
}
.carousel__item:last-child {
  margin-right: 0;
}
.carousel__redirect {
  color: var(--color-base);
  text-decoration: none;
}
.carousel__image {
  min-width: 10rem;
  max-width: 0;
}
.carousel__name {
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  background-color: #4a4a4a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mySwiper-typegames {
    margin: 2rem;
}

.carousel__sports {
    color: white;
    font-size: 1.4rem;
    margin: 1rem 3rem;
    padding: 0;
    display: flex;
    align-items: center;
}
.carousel__sports--text{
  width : 25%;
}
.main__color {
    color: var(--color-base);
}
.mySwiper-typesports__text {
    padding: 0.5rem 0;
}
/*SINGLE GAME SPINNER STYLES */

.single-ring
{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  background:transparent;
  border:3px solid #3c3c3c;
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  letter-spacing:4px;
  text-transform:uppercase;
  text-shadow:0 0 10px #ff6300;
  box-shadow:0 0 20px rgba(0,0,0,.5);
}
/*.single-ring:before
{
  content:'';
  position:absolute;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid #fff000;
  border-right:3px solid #fff000;
  border-radius:50%;
  animation:animateC 2s linear infinite;
}
*/
.circ{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate 2s linear infinite;
}
.circ:before
{
  content:'';
  position:absolute;
  width:16px;
  height:16px;
  border-radius:50%;
  background:#ff6300;
  top:-6px;
  right:-8px;
  box-shadow:0 0 20px #ff6300;
}
@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}

</style>
