<template>
  <swiper :slides-per-view="slidesPerView_" :space-between="spaceBetween_" :slidesPerGroup="slidesPerGroup_" :loop="loop_" :loopFillGroupWithBlank="loopFillGroupWithBlank_" :modules="modules" :navigation="navigation_" :autoplay="autoPlay_" >
    <swiper-slide :style="showNames? 'height:130px;':''" :class="showNames? 'swipper-slide-prop':''" v-for="slide in slides" :key="slide.id">
      <div >
        <img :style="showNames? 'margin-bottom: 15px;':''" class="slider-img-class" @click="click(slide.id)" :src="slide.url" />
        <span style="padding: 0.5rem 0;" v-if="showNames">{{tr(slide.id,lang)}}</span>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script>
  import { Autoplay, Pagination, Navigation  } from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/vue';

  import 'swiper/css';
  import 'swiper/css/effect-fade';
  import Ut from './utils/Ut';


  export default {
    props:["slides","onClick",'slidesPerView','autoPlay', 'navigation', 'spaceBetween', 'showNames', 'slidesPerGroup', 'loop','loopFillGroupWithBlank', 'lang'],
    components: {
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        slidesPerView_:1,
        autoPlay_:{delay:10000},
        navigation_:false,
        spaceBetween_:50,
        slidesPerGroup_:1,
        loop_:false,
        loopFillGroupWithBlank_:false
      }
    },
    setup() {
      return {
        modules: [Pagination, Navigation, Autoplay],
      };
    },
    methods:{
      click(slide_id){
        this.onClick(slide_id);
      },
      tr(code, lang) {
          return Ut.tr(code, lang);
      },
    },
    mounted(){
      if(this.slidesPerView!=null) this.slidesPerView_= this.slidesPerView
      if(this.autoPlay!=null) this.autoPlay_=this.autoPlay
      if(this.navigation!=null) this.navigation_=this.navigation
      if(this.spaceBetween!=null) this.spaceBetween_=this.spaceBetween
      if(this.slidesPerGroup!=null) this.slidesPerGroup_=this.slidesPerGroup
      if(this.loop!=null) this.loop_=this.loop
      if(this.loopFillGroupWithBlank!=null) this.loopFillGroupWithBlank_=this.loopFillGroupWithBlank

    }
  };
</script>
<style scoped> * {color:white;} 
.slider-img-class{
    max-width: 100%;
    height: auto;
    display: block;

}
.swipper-slide-prop{
   text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center !important;
    align-items: center;
    cursor: pointer;
    
}
.swipper-slide-prop:hover{
  background-color: rgba(192, 192, 192, 0.227);
}
</style>