
<script>
import Ut from '../utils/Ut';
import Init from '../utils/Init';
import axios from 'axios';

import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

let options = Init.getOptions();

const headers = {
    Client: 'PLAY',
    Clientauth: 'PLAY2024013115391PLAYPLAYPLAY418',
    "X-Tenant": options['X-Tenant']
}

//Constantes que se emplean en todo el proyecto
//const BACKEND='https://lobby.apiuniversalsoft.com';
const CRASH_GAMES = [];
const CATEGORIES = [
	{ code: "home", name: "HOME", claz: 'Fsub-header-poker', status: false },
	//{ code: "esports", name: "ESPORTS", claz: 'Fsub-header-poker', status: false },
	{ code: "sportbook", name: "DEPORTES", claz: 'Fsub-header-poker', status: false },
	{ code: "hipica", name: "HIPICA", claz: 'Fsub-header-hipica', status: false },
	{ code: "crash_game", name: "CRASH GAMES", claz: 'Fsub-header-poker', isnew: true, status: false },
	{ code: "slot", name: "CASINO", claz: 'Fsub-header-slot', status: false },
	{ code: "slot_live", name: "CASINO EN VIVO", claz: 'Fsub-header-slot', status: false },
	{ code: "scratch", name: "RASPA Y GANA", claz: 'Fsub-header-scratch', status: false },
	{ code: "tv", name: "TV GAMES", claz: 'Fsub-header-tvtv', status: false },
	{ code: "virtual", name: "JUEGOS VIRTUALES", claz: 'Fsub-header-virtual', status: false },
	{ code: "poker", name: "POKER", claz: 'Fsub-header-poker', status: false },
];

const SLOT_GAME_SECTIONS = [
	{ code: 'TOP', name: "TOP GAMES", list: [], views: ['slot'], shown: true, showInFilter: true },
	{ code: 'POPU', name: "POPULARES", list: [], views: ['slot','scratch'], shown: true, showInFilter: true },
	//{ code: 'POPU', name: "POPULARES", list: [], views: ['scratch'], shown: false, showInFilter: true },
	{ code: 'FAST', name: "CRASH GAMES", list: [], views: ['crash_game'], shown: false, showInFilter: false },
	{ code: 'VIRT', name: "JUEGOS VIRTUALES", list: [], views: ['virtual'], shown: false, showInFilter: false },
	{ code: 'NUEV', name: "NUEVOS", list: [], views: ['slot'], shown: true, showInFilter: true },
	{ code: 'DROP', name: "DROPS & WINS", list: [], views: ['slot'], shown: true, showInFilter: true },
	//{ code: 'CLAS', name: "OTROS JUEGOS", list: [], views: ['slot'], shown: true, showInFilter: true },
	{ code: 'CLAS', name: "OTROS JUEGOS", list: [], views: ['slot', 'scratch'], shown: false, all: false, showInFilter: true },
	{ code: 'BACC', name: "BACCARAT", list: [], views: ['slot_live'], shown: true },
	{ code: 'RLIV', name: "RULETA", list: [], views: ['slot_live'], shown: true },
	{ code: 'BJLIV', name: "BLACKJACK", list: [], views: ['slot_live'], shown: true },
	{ code: 'MWLIV', name: "MEGAWHEEL", list: [], views: ['slot_live'], shown: true },
	{ code: 'LOTTO', name: "LOTERIA", list: [], views: ['slot_live'], shown: true },
	{ code: 'SICBO', name: "SICBO", list: [], views: ['slot_live'], shown: true },
    { code: 'TVLIV', name: "GAME SHOWS", list: [], views: ['slot_live'], shown: true },
	{ code: 'ALL', name: "TODOS LOS JUEGOS", list: [], views: ['slot', 'scratch'], shown: false },
	{ code: 'BUSQ', name: "BUSQUEDA", list: [], views: ['slot', 'slot_live','home'], shown: false },
	{ code: 'FAVS', name: "FAVORITOS", list: [], views: ['slot', 'slot_live','home'], shown: false },
	{ code: 'BRAND', name: "--", list: [], views: ['slot', 'slot_live'], shown: false },

];

const lang = options.lang||'es';
const env=options.env;
const default_category=options.category;
const ASSETS_URL=options.ASSETS_URL;
const BACKEND_URL=options.BACKEND_URL;
// const BACKEND_URL_NODE=options.BACKEND_URL_NODE
const TOKEN = options.TK;
//const USER=options.USER;
//const CLIENT=options.CLIENT;
const theme=options.theme;
let usertoken=options.usertoken;
const API_URL=  options.API_URL;
const IS_MOBILE=options.IS_MOBILE;
//const clientconf=options.clientconf;
const IS_PROD = (env == 'PRODUCTION' || env == 1) ? 1 : 0;

const itemsxPage = 20;

const homeSlides=[
  {id:'slot', url:`${ASSETS_URL}/casino/images/banner/bannerv19.png`},
  {id:'aviator', url:`${ASSETS_URL}/casino/images/banner/bannerv18.png`},
  {id:"peaky_blinder",url:`${ASSETS_URL}/casino/images/banner/bannerv17.png`},
  {id:"casino_slots",url:`${ASSETS_URL}/casino/images/banner/bannerv1.png`},
];
const slotSlides=[
  {id:'spaceman', url:`${ASSETS_URL}/casino/images/banner/bannerv19.png`},
  {id:'aviator', url:`${ASSETS_URL}/casino/images/banner/bannerv18.png`},
  {id:"peaky_blinder",url:`${ASSETS_URL}/casino/images/banner/bannerv17.png`},
  {id:"casino_slots",url:`${ASSETS_URL}/casino/images/banner/bannerv1.png`},
];
const slotliveSlides=[
  {id:'roulette', url:`${ASSETS_URL}/casino/images/banner/spanish-roulette.png`},
  {id:'roulette-2', url:`${ASSETS_URL}/casino/images/banner/bannerv16.png`},
  {id:"slotlive",url:`${ASSETS_URL}/casino/images/banner/bannerv2.png`},
];
const crashSlides=[
  {id:'crash_game', url:`${ASSETS_URL}/casino/images/banner/crash-games-portada.png`},
];
const virtualSlides=[
  {id:'virtual', url:`${ASSETS_URL}/casino/images/banner/bannerv3.png`},
];

const slotMidSlides=`${ASSETS_URL}/casino/images/tournament3.png`;

const scratchMidSlides=`${ASSETS_URL}/casino/images/raspayganaPEN.png`;

const scratchSlides=[
  {id:'scratch', url:`${ASSETS_URL}/casino/images/banner/bannerv11.png`},
];

const homeSportlides=[
  {id:'live', url:`https://static.novassets.com/sports/mobile/big/rest/products/live.jpg`},
  {id:'soccer', url:`https://static.novassets.com/sports/mobile/big/rest/sports/soccer.jpg`},
  {id:"basketball",url:`https://static.novassets.com/sports/mobile/big/rest/sports/basketball.jpg`},
  {id:"basketball",url:`https://static.novassets.com/sports/mobile/big/rest/sports/boxing.jpg`},
  {id:"baseball",url:`https://static.novassets.com/sports/mobile/big/rest/sports/baseball.jpg`},
];
const homeSlotlides=[
  {id:'live', url:`https://static.novassets.com/sports/mobile/big/rest/products/live.jpg`},
  {id:'soccer', url:`https://static.novassets.com/sports/mobile/big/rest/sports/soccer.jpg`},
  {id:"basketball",url:`https://static.novassets.com/sports/mobile/big/rest/sports/basketball.jpg`},
  {id:"basketball",url:`https://static.novassets.com/sports/mobile/big/rest/sports/boxing.jpg`},
  {id:"baseball",url:`https://static.novassets.com/sports/mobile/big/rest/sports/baseball.jpg`},
];
const homeSlotLivelides=[
  {id:'live', url:`https://static.novassets.com/sports/mobile/big/rest/products/live.jpg`},
  {id:'soccer', url:`https://static.novassets.com/sports/mobile/big/rest/sports/soccer.jpg`},
  {id:"basketball",url:`https://static.novassets.com/sports/mobile/big/rest/sports/basketball.jpg`},
  {id:"basketball",url:`https://static.novassets.com/sports/mobile/big/rest/sports/boxing.jpg`},
  {id:"baseball",url:`https://static.novassets.com/sports/mobile/big/rest/sports/baseball.jpg`},
];
const homeCategorySlides=[
  {id:'sportbook', url:`${ASSETS_URL}/casino/images/banner/bannerv19.png`},
  {id:'hipica', url:`${ASSETS_URL}/casino/images/banner/bannerv18.png`},
  {id:"slot",url:`${ASSETS_URL}/casino/images/banner/bannerv17.png`},
  {id:"slot_live",url:`${ASSETS_URL}/casino/images/banner/bannerv1.png`},
];
const homeSportsIcons=[
  {id:'futbol', url:`${ASSETS_URL}/banners/EJEMPLO/HOMESLIDES/icon2.png`},
  {id:'basket', url:`${ASSETS_URL}/banners/EJEMPLO/HOMESLIDES/icon4.png`},
  {id:"tenis",url:`${ASSETS_URL}/banners/EJEMPLO/HOMESLIDES/icon5.png`},
  {id:"goleadores",url:`${ASSETS_URL}/banners/EJEMPLO/HOMESLIDES/icon1.png`},
  {id:"especiales",url:`${ASSETS_URL}/banners/EJEMPLO/HOMESLIDES/icon3.png`},
];

const typeGames = [
    { type:'Deportes', code:'sportbook',
        listgame : [
            { name : 'En Vivo',code:'live', image : 'https://static.novassets.com/sports/mobile/big/rest/products/live.jpg' },
            { name : 'Fútbol', code:'soccer',image : 'https://static.novassets.com/sports/mobile/big/rest/sports/soccer.jpg'},
            { name : 'Basketball',code:'basket', image : 'https://static.novassets.com/sports/mobile/big/rest/sports/basketball.jpg' },
            { name : 'Boxing', code:'boxing', image : 'https://static.novassets.com/sports/mobile/big/rest/sports/boxing.jpg' },
            { name : 'Baseball', code:'baseball',  image : 'https://static.novassets.com/sports/mobile/big/rest/sports/baseball.jpg' },
        ]
    },
    { type:'Casino', code:'slot',
        listgame : [
            { name : 'Buffalo King', image : 'https://d2zzz5z45zl95g.cloudfront.net/us_img_games/slot_Pragmatic_1_vswaysbufking.png' },
            { name : 'Wolf Gold', image : 'https://d2zzz5z45zl95g.cloudfront.net/us_img_games/slot_Pragmatic_1_vs25wolfgold.png'},
            { name : 'Mustang Gold', image : 'https://d2zzz5z45zl95g.cloudfront.net/us_img_games/slot_Pragmatic_1_vs25mustang.png' },
            { name : 'Hot Fruits', image : 'https://d2zzz5z45zl95g.cloudfront.net/us_img_games/slot_Eurasian_1_21_EUP.png' },
            { name : 'Neptune Treasure Bingo', image : 'https://d2zzz5z45zl95g.cloudfront.net/us_img_games/slot_Eurasian_1_24_EUP.png' },
        ]
    },
    { type:'Casino en vivo', code:'slot_live',
        listgame : [
            { name : 'Roulette Azure', image : 'https://d2zzz5z45zl95g.cloudfront.net/us_img_games/slot_live_Pragmatic_1_227.png' },
            { name : 'Baccarat 3', image : 'https://d2zzz5z45zl95g.cloudfront.net/us_img_games/slot_live_OneTouch_1_2074915_OTP.png'},
            { name : 'Speed Roulette 1', image : 'https://d2zzz5z45zl95g.cloudfront.net/us_img_games/slot_live_Pragmatic_1_203.png' },
            { name : 'Blackjack 6 - Azure', image : 'https://d2zzz5z45zl95g.cloudfront.net/us_img_games/slot_live_Pragmatic_1_512.png' },
            { name : 'Mega Wheel', image : 'https://d2zzz5z45zl95g.cloudfront.net/us_img_games/slot_live_Pragmatic_1_801.png' },
        ]
    },
];


export default {
    name: 'CoreClassic',
  components: { Swiper, SwiperSlide },

  setup() {
      const onSwiper = () => {console.log('swiper');};
      const onSlideChange = () => {console.log('slide change');};
      return { onSwiper, onSlideChange, modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay]};
    },
  data() {
    return {
        lang,
    slotSlides, slotliveSlides, homeSlides,homeSportlides,homeSlotlides,homeSlotLivelides,homeCategorySlides,homeSportsIcons,crashSlides,virtualSlides,scratchSlides,scratchMidSlides,slotMidSlides,
      typeGames,
      IS_PROD,
      orientation: document.documentElement.clientWidth > 450 ? "H" : "V",
      pageLoaded: false,
      preloader: true,
      singleGamePreloader:false,
      seeMoreFlag: true,
      loadImage: false,
      showTempGameImage: false,
      is_single_game: false,
      CATEGORIES,
      CRASH_GAMES,
      categoryofserver: default_category,
      currentCategoryFilter: "ALL",
      activeSlotLiveSection: null,
      SLOT_GAME_SECTIONS,
      is_single_game_view: false,
      active_view: default_category,
      active_view_name: "CASINO",
      brands: [{ name: "Todos" }],
      slotlive_brands: [{ name: "Todos" }],
      game_types: [],
      ASSETS_URL,
      user: {},
      client: {},
      clientconf: {},
      pagetheme: theme,
      itemsxPage,
      usertoken,
      API_URL,
      device: IS_MOBILE ? "mb" : "wb",
      favorites: [],
      myBetsFilter: {},
      mybets: [],
      text_search: "",
      movements: [],
      SPORTBOOK: {},
      HIPICA: {},
      POKER: {},
      ESPORTS: {},
      AVIATOR: {},
      proveedor_select: "brands",
      current_proveedor_select: "brands",
      showMenu: 1,
      clientColor: "purple",
      textColor:'black',
      showHome: false,
      sectionLoader: true,
      goTopVisible: false,
      socketText: []
    };
    
  },
  
    computed: {
        // computed...
    },

  methods:{
    setActiveView(view){
        this.showGames(view);
        
        
    },
    isMobile(){
        //return (/iPhone|iPad|iPod/i.test(navigator.userAgent));
        return (/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform));
    },
      tr(code) {
          return Ut.tr(code, this.lang);
      },
    trCurr(code, symbol) {
		return Ut.trCurr(code, symbol);
	},
    returnClientHome() {window.parent.location = this.client.home;},

    async launchGame(p, b, g, m, id = null) {
        if(!this.usertoken || this.usertoken.length < 141){
            options = Init.getOptions();
            this.usertoken=options.usertoken;
        }
        if(this.usertoken && this.usertoken.length >= 141 ){ 
            let mode = this.device;
            this.saveClick(id); //registra el numero de cliks en la bd
            const { data } = await axios.get(BACKEND_URL + `/auth?s=${this.user.serial}&e=${this.client.endpoint}&t=${this.usertoken}`);
            let response = data;
            setTimeout(async() => {
                let url = API_URL + "/api/launch?gameid=" + g + "&p=" + p + "&b=" + b + "&m=" + mode + "&sessionid=" + response.val.token;
                if (/avt/.test(p)) {
                    url +="&r=true";
                    let data = await fetch(url);
                    url = data.url;
                } 
                //let url = API_URL + "/api/launch?gameid=" + g + "&p=" + p + "&b=" + b + "&m=" + mode + "&sessionid=" + this.usertoken;
                var on_popup_ = true;
                if (document.getElementById("changecolor")) on_popup_ = !document.getElementById("changecolor").checked;

                if (on_popup_) {
                    if (this.active_view == "sportbook") {
                        if (this.SPORTBOOK.p == "edg") url += "&currentgame=Home";
                    Ut.juego_simple(url, this.showMenu);
                    this.get_altura();
                    } else if (this.active_view == "hipica" || this.active_view == "esports") {
                    Ut.juego_simple(url, this.showMenu);
                    this.get_altura();
                    } else {
                    this.openIframe(url);
                    }
                } else { window.open(url, "_blank"); }
                
            }, 300);
        }else{
            this.nologinevent(); return; 
        }
        
    },
    async onGametypeChange(section_code) {
        this.SLOT_GAME_SECTIONS.filter(e => e.views.includes(this.active_view) && e.list.length > 0).forEach(e => e.shown = true);
        this.SLOT_GAME_SECTIONS[this.SLOT_GAME_SECTIONS.length - 1].shown = false;
        this.SLOT_GAME_SECTIONS[this.SLOT_GAME_SECTIONS.length - 2].shown = false;
        this.SLOT_GAME_SECTIONS[this.SLOT_GAME_SECTIONS.length - 3].shown = false;
        this.SLOT_GAME_SECTIONS[this.SLOT_GAME_SECTIONS.length - 4].shown = false;
        await Ut.sleep(0.2);
        this.gotoSection(section_code);
    },
    gotoSection(section_code) {
        if (this.$refs["gamesection-"+section_code] != null) {
            const [el] = this.$refs["gamesection-"+section_code];
            el.scrollIntoView({ behavior: "instant" });
      }
       //Ut.gotoSection(section_code);
    },
    //------------Favoritos----------------------------------------
    //-------------------------------------------------------------
    async listFavs() {
    if(!this.usertoken || this.usertoken.length != 141){
        options = Init.getOptions();
        this.usertoken=options.usertoken;
    }
    if(this.usertoken && this.usertoken.length == 141 ){
        let category = this.active_view;
        if (this.active_view != 'slot' || this.active_view != 'slot_live' || this.active_view != 'crash_game')category='slot';
        this.sectionLoader = true;
        let i = this.SLOT_GAME_SECTIONS.findIndex(e => e.code == 'FAVS');
        this.SLOT_GAME_SECTIONS.forEach(e => e.shown = false);
        this.SLOT_GAME_SECTIONS[i].shown = true;
        let url = BACKEND_URL+`/favs?c=${category}&m=${this.device}&t=${this.usertoken}`;
        const { data } = await axios.get(url, { headers: { "validation-data": this.usertoken } })
        let response = data;
        this.sectionLoader = false;
        this.SLOT_GAME_SECTIONS[i].list = response.map(e => ({ ...e, isfav: true }));
        this.favorites = response;
    }else{
        this.nologinevent(); return;
    }
    },

    async saveFavs(game, action) {
        if(this.usertoken && this.usertoken.length == 141 ){ 
            if (action == "remove") {
            let i = this.SLOT_GAME_SECTIONS.findIndex(e => e.code == 'FAVS');
            let removeIndex = this.SLOT_GAME_SECTIONS[i].list.findIndex(e => e.id == game.id);
            removeIndex == 0 ? this.SLOT_GAME_SECTIONS[i].list.shift() : this.SLOT_GAME_SECTIONS[i].list.splice(removeIndex, 1);
            game.isfav = false;
        } else {
            game.isfav = true;
        }
        await (await fetch(BACKEND_URL+'/saveFavs', {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({ t: this.usertoken, g: game.id, action: action })
        })).json();
        }else{
            this.nologinevent(); return;
        }
        
    },
    paintFavorites(list) {
        let favs = [...this.favorites];
        let l = list.map(g => ({ ...g, isfav: favs.find(e => e.id == g.id) ? true : false }));
        return l;
    },

    //------------------------------------------------
    //----------------Clicks counter -----------------
    //------------------------------------------------
    async saveClick(gameid) {
        await (await fetch(BACKEND_URL+'/saveClick', {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({ t: this.usertoken, g: gameid })
        })).json();
    },

    async returnToHome(){
        this.showGames('home');
        options.onReturnHome();
    },


    //--------------- SCRATCH -----------------------
    //-----------------------------------------------
    async showScratchView() {
        let category = "scratch";
        this.sectionLoader = true;
        let i = this.SLOT_GAME_SECTIONS.findIndex(e => e.code == 'CLAS' && e.views.includes(this.active_view));
        this.SLOT_GAME_SECTIONS[i].all = false;
        this.SLOT_GAME_SECTIONS.forEach(e => e.list = []);
        this.SLOT_GAME_SECTIONS.forEach(e => e.shown = false);
        this.SLOT_GAME_SECTIONS.filter(e => e.views.includes(category)).forEach(e => e.shown = true);
        this.SLOT_GAME_SECTIONS[this.SLOT_GAME_SECTIONS.length - 1].shown = false;
        this.SLOT_GAME_SECTIONS[this.SLOT_GAME_SECTIONS.length - 2].shown = false;
        this.SLOT_GAME_SECTIONS[this.SLOT_GAME_SECTIONS.length - 3].shown = false;
        this.SLOT_GAME_SECTIONS[this.SLOT_GAME_SECTIONS.length - 4].shown = false;
        i = this.SLOT_GAME_SECTIONS.findIndex(e => e.views.includes(category));
        await this.getGamesByType(category, this.SLOT_GAME_SECTIONS[i].code);
        this.sectionLoader = false;
    },

    async orderBy(orderType) {
        let device = this.device;
        let i = this.SLOT_GAME_SECTIONS.findIndex(e => e.code == 'CLAS' && e.views.includes(this.active_view));
        this.SLOT_GAME_SECTIONS.forEach(e => e.shown = false);
        this.SLOT_GAME_SECTIONS[i].shown = true;
        this.SLOT_GAME_SECTIONS[i].all = true;
        const { data } = await axios.get(BACKEND_URL + `/games?c=scratch&m=${device}&page=1&xpage=10000&curr=${this.user.currency}`);
        let response = data;
        if (orderType == "less") this.SLOT_GAME_SECTIONS[i].list = response.sort((game1, game2) => { return game1.min_bet > game2.min_bet ? 1 : -1; });
        else if (orderType == "higher") this.SLOT_GAME_SECTIONS[i].list = response.sort((game1, game2) => { return game1.min_bet < game2.min_bet ? 1 : -1; });
        else this.showGames('scratch');
    },


    //----------------GENERAL SEARCH METHOD-----------------
    //------------------------------------------------------
    async search(category, page = 1, paginate = 0) {
        let device = this.device;
        let text = this.text_search;
        let i = this.SLOT_GAME_SECTIONS.findIndex(e => e.code == 'BUSQ');
        if (text != '') {
            this.sectionLoader = true;
            this.SLOT_GAME_SECTIONS.forEach(e => e.shown = false);
            this.SLOT_GAME_SECTIONS[i].shown = true;
            const { data } = await axios.get(BACKEND_URL + `/games?c=${category}&m=${device}&g=${text}&page=${page}`)
            let response = data;
            this.sectionLoader = false;
            this.SLOT_GAME_SECTIONS[i].list = paginate ? this.SLOT_GAME_SECTIONS[i].list.concat(response) : response;
        } else {
            this.SLOT_GAME_SECTIONS.filter(e => e.views.includes(category)).forEach(e => e.shown = true);
            this.SLOT_GAME_SECTIONS[i].shown = false;
            i = this.SLOT_GAME_SECTIONS.findIndex(e => e.code == 'BRAND');
            this.SLOT_GAME_SECTIONS[i].shown = false;
            i = this.SLOT_GAME_SECTIONS.findIndex(e => e.code == 'FAVS');
            this.SLOT_GAME_SECTIONS[i].shown = false;
            i = this.SLOT_GAME_SECTIONS.findIndex(e => e.code == 'ALL');
            this.SLOT_GAME_SECTIONS[i].shown = false;
            if(category == 'slot_live')this.showSlotliveView();
        }
    },

    //------------LIST GAMES BY BRAND AND CATEGORY----------
    async onBrandChange(category, brand, page = 1, paginate = 0) {
        this.proveedor_select = brand;
        let device = this.device;
        if (device == 'mb') document.getElementById(category == 'slot' ? 'miprovslot' : 'miprovlive').style.display = 'none';
        this.sectionLoader = true;
        this.SLOT_GAME_SECTIONS.forEach(e => e.shown = false);
        let i = this.SLOT_GAME_SECTIONS.findIndex(e => e.code == 'BRAND');
        this.SLOT_GAME_SECTIONS[i].name = brand;
        this.SLOT_GAME_SECTIONS[i].shown = true;
        if (paginate == 0) this.SLOT_GAME_SECTIONS[i].list = [];
        let response = brand == "brands" ? this.showGames(category) : await (await fetch(BACKEND_URL+`/games?c=${category}&m=${device}&b=${brand}&page=${page}`)).json();
        this.sectionLoader = false;
        this.SLOT_GAME_SECTIONS[i].list = paginate ? this.SLOT_GAME_SECTIONS[i].list.concat(response) : response;
    },
    //---------------------END--------------------------------------

    //---------------------MAIN METHD-------------------------------
    async showGames(category) {
        let mode = this.device;
        this.is_single_game_view = false;
        if (category == 'sportsbook' || category == 'sportsbook2') category = 'sportbook';
        this.active_view = category;
        
        switch (category) {
            case 'sportbook':this.active_view_name = 'deportes'.toUpperCase(); this.showSingleGame(this.SPORTBOOK.p, this.SPORTBOOK.b,  this.SPORTBOOK.g, mode, this.SPORTBOOK.id); break;
            case 'slot': this.active_view_name = 'casino'.toUpperCase();this.hideiframe(); this.showSlotView(); this.proveedor_select = 'brands'; break;
            case 'slot_live': this.active_view_name = 'casino en vivo'.toUpperCase();this.hideiframe(); this.showSlotliveView(), this.proveedor_select = 'brands'; break;
            case 'scratch':this.active_view_name = 'raspa y gana'.toUpperCase(); this.hideiframe(); this.showScratchView(); break;
            case 'home': this.active_view_name = 'inicio'.toUpperCase();this.hideiframe(); this.showHomeView(); break;
            case 'tv':this.active_view_name = 'tv games'.toUpperCase(); this.hideiframe(); break;
            case 'virtual': this.active_view_name = 'juegos virtuales'.toUpperCase();this.hideiframe(); this.showVirtualView(); break;
            case 'poker': this.active_view_name = 'poker'.toUpperCase();this.hideiframe(); this.showSingleGame( this.POKER.p, this.POKER.b,  this.POKER.g, mode); break;
            case 'hipica': this.active_view_name = 'hipica'.toUpperCase();this.showSingleGame(this.HIPICA.p,this.HIPICA.b, this.HIPICA.g, mode, this.HIPICA.id); break;
            case 'esports':this.active_view_name = 'esports'.toUpperCase(); this.showSingleGame(this.ESPORTS.p, this.ESPORTS.b, this.ESPORTS.g, mode); break;
            case 'crash_game':this.active_view_name = 'crash games'.toUpperCase(); this.hideiframe(); this.showCrashView(); break;
        }
    },
    hideiframe() {
        if (document.getElementById('games-single')) {
            let element = document.getElementById('games-single');
            element.style = "display: none;";
            let iframe = document.getElementById('single-game-iframe')
            iframe.setAttribute("src", '');
        }
    },
    async showSingleGame(p, b, g, mode) {
        let options = Init.getOptions();
        this.usertoken=options.usertoken;
        console.log("show games token ==> ", options.usertoken);
        if(this.usertoken && this.usertoken.length == 141){
            this.showSingleGameIframe(p, b, g, mode, this.usertoken);
        }else{
            this.showSingleGameIframe(p, b, g, mode, TOKEN);
        }
    },
    async showSingleGameIframe(p, b, g, m, t) {
        this.singleGamePreloader = true;
        this.is_single_game_view = true;
        let response = {};
        console.log("preloader: ", this.singleGamePreloader);
        options = Init.getOptions();
        if(!this.usertoken || this.usertoken.length != 141){
            this.usertoken=options.usertoken;
        }
        if(this.usertoken)response = await (await fetch(BACKEND_URL+`/auth?s=${this.user.serial}&e=${this.client.endpoint}&t=${this.usertoken}`)).json();
            let iframe = document.getElementById('single-game-iframe')
            document.getElementById("games-single").style = "";

            if(this.device=='mb'){
                this.get_altura();
                iframe.style.cssText = this.showMenu == 1 ? 'height: 100%;width: 100%;top:0;position:relative;' : 'flex: 1 1 0%;width: 100%;height: 85vh;position: fixed;left: 0;';
            }else{
                iframe.style.cssText = this.showMenu == 1 ? 'flex: 1 1 0%;width: 100%;height: 97%;position: fixed;left: 0;' : 'flex: 1 1 0%;width: 100%;height: 100%;position: fixed;left: 0;';
            }
            let url = API_URL + `/api/launch?gameid=${g}&p=${p}&b=${b}&m=${m}&sessionid=${this.usertoken ? response.val.token : t }`;
            if(g == 'wintech_gaming') {
                const { data } = await axios.get(url);
                url = data.url;
            }
            this.singleGamePreloader = false ;
            /*if(p == 'edg'){
                const userLanguage = options.language || 'en';
                console.log("UserLang:", userLanguage); // Usar el idioma de options como defaultLanguage
                url += `&lang=${userLanguage}`;
                console.log("url:", url);
            }*/
            //let url = API_URL + `/api/launch?gameid=${g}&p=${p}&b=${b}&m=${m}&sessionid=${t}`;
            if (this.active_view == "sportbook" && this.SPORTBOOK.p == "edg") url += "&currentgame=Home";
            iframe.setAttribute("src", url);
    },
    async initViews() {
        //let gametypes = await (await fetch(BACKEND_URL+"/gameTypes?c=" + default_category)).json();

       // this.game_types = gametypes.map(e => ({ code: e.code, total: e.total, name: Ut.tr(e.code, 'es') }));
    },

    async showSlotView() {
        let category = "slot";
        this.sectionLoader = true;
        const { data } = await axios.get(BACKEND_URL + "/brands?c=" + category + "&client=" + this.client.code + "&m=" + this.device)
        let brands = data;
        // Filtrar marcas y ordenar alfabéticamente
        this.brands = brands.filter(brand => brand.name !== 'Digtain Sports Book').sort((a, b) => a.name.localeCompare(b.name));
        const proveedoresIndex = this.brands.findIndex(brand => brand.name === 'Proveedores');
        if (proveedoresIndex !== -1) {
            const proveedoresBrand = this.brands.splice(proveedoresIndex, 1)[0];
            this.brands.unshift(proveedoresBrand);
        }
        this.SLOT_GAME_SECTIONS.forEach(e => e.list = []);
        this.SLOT_GAME_SECTIONS.filter(e => e.views.includes(category)).forEach(e => e.shown = true);
        //this.SLOT_GAME_SECTIONS.forEach( e=>e.shown=true);
        this.SLOT_GAME_SECTIONS[this.SLOT_GAME_SECTIONS.length - 1].shown = false;
        this.SLOT_GAME_SECTIONS[this.SLOT_GAME_SECTIONS.length - 2].shown = false;
        this.SLOT_GAME_SECTIONS[this.SLOT_GAME_SECTIONS.length - 3].shown = false;
        this.SLOT_GAME_SECTIONS[this.SLOT_GAME_SECTIONS.length - 4].shown = false;
        await this.getGamesByType('slot', 'TOP');
    },

    async showCrashView() {
        let category = "crash_game";
        this.sectionLoader = true;
        let i = this.SLOT_GAME_SECTIONS.findIndex(e => e.code == 'FAST' && e.views.includes(this.active_view));
        this.SLOT_GAME_SECTIONS.forEach(e => e.shown = false);
        this.SLOT_GAME_SECTIONS[i].shown = true;
        this.SLOT_GAME_SECTIONS[i].list = [];
        await this.getGamesByType(category, 'FAST');
    },
    async showVirtualView() {
        let category = "virtual";
        this.sectionLoader = true;
        let i = this.SLOT_GAME_SECTIONS.findIndex(e => e.code == 'VIRT' && e.views.includes(this.active_view));
        this.SLOT_GAME_SECTIONS.forEach(e => e.shown = false);
        this.SLOT_GAME_SECTIONS[i].shown = true;
        this.SLOT_GAME_SECTIONS[i].list = [];
        await this.getGamesByType(category, 'VIRT');
    },
    
    async showHomeView() {
        this.sectionLoader = true;
        //let i = this.SLOT_GAME_SECTIONS.findIndex(e => e.code == 'FAST' && e.views.includes(this.active_view));
        this.SLOT_GAME_SECTIONS.forEach(e => e.shown = false);
        //this.SLOT_GAME_SECTIONS[i].shown = true;
        //this.SLOT_GAME_SECTIONS[i].list = [];
    },

    async listAllGames() {
        let i = this.SLOT_GAME_SECTIONS.findIndex(e => e.code == 'ALL' && e.views.includes(this.active_view));
        this.SLOT_GAME_SECTIONS.forEach(e => e.shown = false);
        this.SLOT_GAME_SECTIONS[i].shown = true;
        this.SLOT_GAME_SECTIONS[i].list = [];
        await this.getGamesByType(this.active_view, 'ALL', 1);
    },

    async showSlotliveView() {

        let category = "slot_live";
        this.sectionLoader = true;
        this.SLOT_GAME_SECTIONS.forEach(e => e.list = []);
        this.SLOT_GAME_SECTIONS.filter(e => e.views.includes(category)).forEach(e => e.shown = true);
        this.SLOT_GAME_SECTIONS[this.SLOT_GAME_SECTIONS.length - 1].shown = false;
        this.SLOT_GAME_SECTIONS[this.SLOT_GAME_SECTIONS.length - 2].shown = false;
        this.SLOT_GAME_SECTIONS[this.SLOT_GAME_SECTIONS.length - 3].shown = false;
        const { data } = await axios.get(BACKEND_URL + "/brands?c=" + category + "&client=" + this.client.code);
        let brands = data;
        brands[0].name=this.tr('PROV');
        this.slotlive_brands = brands;
        let i = this.SLOT_GAME_SECTIONS.findIndex(e => e.views.includes(category));
        await this.getGamesByType(category, this.SLOT_GAME_SECTIONS[i].code);
    },


    async slot_live_search(action) {
        let btnSearch = document.getElementById("opinput");
        if (action == "opmodal") {
            btnSearch.classList.replace("formsearch", "formsearchactive");
        } else if (action == "clmodal") {
            btnSearch.classList.replace("formsearchactive", "formsearch");
            if (document.getElementById("searchcasino2").value != "") {
                this.showGames('slot_live');
                document.getElementById("searchcasino2").value = "";
            }
        } else {
            //this.text_search = document.getElementById("searchcasino2").value;
            this.search('slot_live');
        }
    },

    //----------------SEE MORE && SEE LEES FOR PAGINATE-------------------
    async viewMore(section_code, brand) {
        let i = this.SLOT_GAME_SECTIONS.findIndex(e => e.code == section_code && e.views.includes(this.active_view));
        let length = this.SLOT_GAME_SECTIONS[i].list.length;
        let page = (length / this.itemsxPage) + 1;
        if (section_code == "BUSQ") {
            this.search(this.active_view, page, 1);
        } else if (section_code == "BRAND") {
            this.onBrandChange(this.active_view, brand, page, 1)
        } else {
            let recursive = false;
            this.getGamesByType(this.active_view, section_code, page, itemsxPage, recursive);
        }
    },
    async viewLess(section_code) {
        if (this.seeMoreFlag == false) this.seeMoreFlag = true;
        let i = this.SLOT_GAME_SECTIONS.findIndex(e => e.code == section_code && e.views.includes(this.active_view));
        let cant = this.SLOT_GAME_SECTIONS[i].list.length % 20 != 0 ? this.SLOT_GAME_SECTIONS[i].list.length % 20 : 20;
        this.SLOT_GAME_SECTIONS[i].list.length = this.SLOT_GAME_SECTIONS[i].list.length - cant;
    },
    //------------------END SECTION-----------------------------//

    buildIMGURL(game) {
        return this.ASSETS_URL + '/us_img_games/' + game.category + '_' + (game.brand.replaceAll(" ", "_")) + '_1_' + game.gameid + '.png'
    },

    indexNextSection(active_view, current_index) {
        var new_i = this.SLOT_GAME_SECTIONS.findIndex((e, index) => index > current_index && e.shown && e.views.includes(active_view));
        return new_i;
    },
    openIframe(url){
        document.body.style.overflow = "hiden";
        let iframe = document.getElementById("iframe");
        if(this.device=='mb')iframe.style = this.orientation == 'V' ? 'flex: 1 1 0%; width: 100%; height: 100vh; background: black;':'flex: 1 1 0%; width: 100vw; height: 100vh; background: black;';
        let modal = document.getElementById("iframeModal");
        iframe.src = url;
        modal.classList.add("active");
    },
    closeIframe(){
        document.body.style.overflow = "auto";
        let modal = document.getElementById("iframeModal");
        let iframe = document.getElementById("iframe");
        modal.classList.remove("active");
        iframe.src = "";
    },
    showDropdowns(element){
        document.getElementById(element).classList.toggle("show");
    },
    scrollToTop(){
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    },
    toggleVisible(){
        const scrolled = document.documentElement.scrollTop;
        this.goTopVisible = scrolled > 400 ? true : false;
    },
    async getGamesByType(category, gametype, page = 1, xpage = 20, recursive = true) {
        let response;
        this.sectionLoader = true;
        let top = 200000;
        let pop = 100000;
        let device = this.device;

        let url = `${BACKEND_URL}/games?m=${device}&page=${page}&xpage=${xpage}&curr=${this.user.currency}`
        if (gametype == "NUEV") {      url+=`&c=${category}&n=true`;}
        else if (gametype == 'TOP') {  url+=`&c=${category}&o=${top}`;}
        else if (gametype == 'POPU') { url+=`&c=${category}&o=${pop}`;}
        else if (gametype == 'ALL') {  url+=`&c=${category}`;} 
        else if (gametype == 'FAST') { url+=`&t=${gametype}`;} 
        else { url+=`&c=${category}&t=${gametype}`;}
        const { data } = await axios.get(url);
        response = data;
        if (gametype == 'FAST')this.CRASH_GAMES = response;
        this.sectionLoader = false;
        response = this.paintFavorites(response);
        let i = this.SLOT_GAME_SECTIONS.findIndex(e => e.code == gametype && e.views.includes(category));
        xpage == 20 ? this.SLOT_GAME_SECTIONS[i].list = this.SLOT_GAME_SECTIONS[i].list.concat(response) : this.SLOT_GAME_SECTIONS[i].list = response;
        if (this.SLOT_GAME_SECTIONS[i].list.length == 0) this.SLOT_GAME_SECTIONS[i].shown = false;
        //let next_i = this.indexNextSection(this.active_view, i);
        let next_i = this.indexNextSection(category, i);
        if (recursive && next_i != -1) await this.getGamesByType(category, this.SLOT_GAME_SECTIONS[next_i].code);
    },

    //---------------- METHODS FOR MODALS TRANSACTIONS DETAILS----------------
    async getGamesByUser() {
        this.myBetsFilter.game_id = "";
        this.myBetsFilter.bet_result = "";
        const { data } = await axios.get(BACKEND_URL + `/gamesByUser?m=${this.device}&t=${this.usertoken}&curr=${this.user.currency}`, {headers})
        this.myBetsFilter.games = data;
    },
    async getMyBets() {
        var { game_id, bet_result, start, end } = this.myBetsFilter;
        var gamename = this.myBetsFilter.games.find(g => g.gameid == this.myBetsFilter.game_id).name;
        this.mybets = [];
        const { data } = await axios.get(BACKEND_URL + `/mybets?t=${this.usertoken}&g_id=${game_id}&type=${bet_result}&start=${start}&end=${end}&m=${this.device}`, {headers})
        this.mybets = data;
        if (this.mybets.length > 0) {
            this.mybets.map((e) => {
                e.date = new Date(e.date).toLocaleDateString('en-US', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });
                e.gamename = gamename;
            });
        } else {
            this.mybets.message = "No se encontraron transacciones";
        }
    },



    async myMovements() {
        var { bet_result, start, end } = this.myBetsFilter;
        this.movements = [];
        const { data } = await axios.get(BACKEND_URL + `/mymovements?t=${this.usertoken}&type=${bet_result}&start=${start}&end=${end}&m=${this.device}`, {headers})
        this.movements = data;
        if (this.movements.length > 0) {

            this.movements.map((e) => {
                e.date = new Date(e.date).toLocaleDateString('en-US', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });
                e.gamename = this.myBetsFilter.games.find(g => e.gameid == g.gameid).name ?? 'null';
            });

        } else {
            this.movements.message = "No se encontraron transacciones";
        }
    },
    async expand_item() {
        var section = document.getElementById("expand_div");
        section.classList.toggle("expand-lg");
    },
    initConfigurations() {
        //client lobby settings cnfiguration
        this.clientconf.settings.map(e => {
            switch (e.code) {
                case 'menu': {
                    this.showMenu = e.show;
                    this.clientColor = e.color ?? 'purple';
                    this.textColor = e.tColor ?? '';
                    break;
                }
            }
            return e.code;
        })
        
        //categories by client
        var conf_ = this.clientconf.categories.map(e => {
            switch (e.code) {
                case 'sportbook': this.SPORTBOOK = { id: e.id, p: e.p, b: e.b, g: e.g }; break;
                case 'poker': this.POKER = { p: e.p, b: e.b, g: e.g }; break;
                case 'hipica': this.HIPICA = { id: e.id, p: e.p, b: e.b, g: e.g }; break;
                case 'esports': this.ESPORTS = { p: e.p, b: e.b, g: e.g }; break;
                case 'crash_game': this.AVIATOR = { p: e.p, b: e.b, g: e.g }; break;
            }
            return e.code;
        });
        this.CATEGORIES = this.CATEGORIES.filter(e => conf_.includes(e.code));

        //
        //banners by client
        if(this.client.banners){
            var banners = JSON.parse(this.client.banners);
            Object.keys(banners).map(e => {
                switch (e) {
                    case "home":
                        this.homeSlides = banners.home.map(banner => {
                            banner.url = `${ASSETS_URL}/banners/`+this.client.name+'/home/'+banner.url+'.png';
                            return banner;
                        });
                        for(let i = 0; i<4; i++){
                            this.homeCategorySlides[i].url=`${ASSETS_URL}/banners/`+this.client.name+'/HOMESLIDES/sliderG'+(i+1)+'.png';
                        }
                        break;
                    case "slot":
                        this.slotSlides = banners.slot.map(banner => {
                            banner.url = `${ASSETS_URL}/banners/`+this.client.name+'/slot/'+banner.url+'.png';
                            return banner;
                        })
                        break;
                    case "slot_live":
                        this.slotliveSlides = banners.slot_live.map(banner => {
                            banner.url = `${ASSETS_URL}/banners/`+this.client.name+'/slotLive/'+banner.url+'.png';
                            return banner;
                        })
                        break;
                    case "scratch":
                        this.scratchSlides = banners.scratch.map(banner => {
                            banner.url = `${ASSETS_URL}/banners/`+this.client.name+'/scratch/'+banner.url+'.png';
                            return banner;
                        })
                        break;
                    case "crash":
                        console.log('This is a',e);
                        this.crashSlides = banners.crash.map(banner => {
                            banner.url = `${ASSETS_URL}/banners/`+this.client.name+'/crash/'+banner.url+'.png';
                            return banner;
                        })
                        break;
                    case "virtual":
                        this.virtualSlides = banners.virtual.map(banner => {
                            banner.url = `${ASSETS_URL}/banners/`+this.client.name+'/virtual/'+banner.url+'.png';
                            return banner;
                        })
                        break;
                    case "slM":
                        this.slotMidSlides = banners.slM.map(banner => {
                            banner.url = `${ASSETS_URL}/banners/`+this.client.name+'/slot/'+banner.url+'.png';
                            return banner.url;
                        })
                        break;
                    case "scM":
                        this.scratchMidSlides = banners.scM.map(banner => {
                            banner.url = `${ASSETS_URL}/banners/`+this.client.name+'/scratch/'+banner.url+'.png';
                            return banner.url;
                        })
                        break;
                }
            })
        }
    },
    orientationEventListener() {
        this.orientation = screen.orientation.angle == 90 ? 'H' : 'V';
    },
    get_altura() {
        let ancho = document.documentElement.clientWidth;
        //let alto = document.documentElement.clientHeight;
        let alto_test = visualViewport.height;
        //let altura_navbar = document.getElementById("navbar-movil").clientHeight;
        let altura_subnavbar = document.getElementById("bartop-movil").clientHeight;
        let content = alto_test - altura_subnavbar;
        this.orientation = ancho > 450 ? 'H' : 'V';
        console.log("Esta en modo ",this.orientation, "content: ",content);
        let iframesingle = document.getElementById("games-single");
        let iframe = document.getElementById("body-slot");
        iframe.style.height = content + 'px';
        iframesingle.style.height = content + 'px';
    },
    async getClientByDomain(){
        try {
            // const headers_ = {
            //     ...headers,
            //     'Content-Type': 'application/json', 
            //     // 'usertoken':this.usertoken 
            // };
            const { data } = await axios.get(BACKEND_URL + '/client')

            this.client = data;
            this.clientconf = JSON.parse(this.client.conf);
        } catch (error) {
            console.log(error);
            alert("PLATAFORMA NO AUTORIZADA");
        }
    },
    async getUserData(){
        const { data } = await axios.get(BACKEND_URL + `/user?t=${this.usertoken}`, {headers}) 
        this.user = data
    },
    //-----------------Eventos que notifican al cliente ----------------
    nologinevent(){
        options.onNoLogin();
    },
    //---------------Fin de Eventos que notifican al cliente------------
  },
  
  async mounted() {
    this.pageLoaded = true;
    this.isMobile();

    await this.getClientByDomain();
    //await this.initViews();
    if(this.usertoken) {
        await this.getUserData();
        //await this.listFavs();
    }
    await this.initConfigurations();
    if (IS_MOBILE) {
        //await this.getGamesByUser();
        this.myBetsFilter.start = new Date().toISOString().substring(0, 10);
        this.myBetsFilter.end = new Date().toISOString().substring(0, 10);
        window.addEventListener('load', this.get_altura);
        visualViewport.addEventListener('resize', this.get_altura);
    }
	await this.showGames(this.active_view);
    window.addEventListener('scroll', this.toggleVisible);
    // Close the dropdown if the user clicks outside of it
    window.onclick = function(event) {
    if (!event.target.matches('.dropbtn')) {
    var dropdowns = document.getElementsByClassName("Universaldropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        openDropdown.classList.remove('show');
      }
    }
  }
}
    this.preloader = false;
  },
  
};
</script>