<template  src="./classic_mb.html"></template>
<style src="./style-mb-default.css" scoped></style>
<script>
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import ClassicCore from '../ClassicCore.vue';
import SlotSwipper from "../../../components/SlotSwipper";
import SlotLiveMenu from "../../../components/SlotLiveMenu";
import DropdownsProviders from "../../DropdownsProviders.vue";
import ScrollTop from "../../ScrollTop.vue";


export default{
    name:'classic-page-mobile',
    extends:ClassicCore,
    components: {
    Carousel,
    Slide,
      Navigation, SlotSwipper, SlotLiveMenu, DropdownsProviders, ScrollTop
  },
data: () => ({
    // carousel settings
}),
methods:{
  showUserModal(modalId){
    let mobileUserModal = document.getElementById(modalId);
    mobileUserModal.style = mobileUserModal.style.display == 'none' ? '' : 'display:none'; 
    console.log(mobileUserModal.style.display);
  },
  listBrandsModal(category){
    let id = category == 'slot' ? 'miprovslot' : 'miprovlive';
    let element = document.getElementById(id).style.display;
    
    if(!element){
      document.getElementById(id).style.display = 'none'; 
    }else{
      document.getElementById(id).style = "";
    }
  },
  onBannerClick(slide_id){
    switch(slide_id){
      case 'spaceman': this.launchGame('pg', 'Pragmatic', '1301', 'mb', 50473); break;
      case 'aviator': this.launchGame('tgg', 'Spribe', '6109_TGG', 'mb', 50418); break;
      case 'peaky_blinder': this.launchGame('pg', 'Pragmatic', 'vs20pblinders', 'mb', 50461); break;
      case 'roulette': this.launchGame('pg', 'Pragmatic', '234', 'mb', 50530);break;
    }
  }

},
}

</script>
<style scoped>

</style>