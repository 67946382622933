<script>
    export default {
    props: ['scroll', 'show'],
        data() {
            return {

            }
        },
        methods: {
            scrollToTop() {
                this.scroll();
            },
            goTopVisible() {
                this.show();
            }
        },
        mounted() {

        }
    };
</script>
<template>
    <a v-show="goTopVisible" class="scroll-top" href="javascript:void(0)" @click="scrollToTop">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill"
            viewBox="0 0 16 16">
            <path
                d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
        </svg>
    </a>
</template>
<style>
    .scroll-top {
        text-decoration: none !important;
        position: fixed;
        z-index: 1000;
        bottom: 20px;
        right: 30px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.7);
        width: 6vh;
        height: 6vh;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 3vh;
        color: var(--color-logo2) !important;
        -webkit-box-shadow: 0px 0px 5px -1px var(--color-logo2);
        box-shadow: 0px 0px 5px -1px var(--color-logo2);
    }
</style>