<script>
    import Ut from './utils/Ut';
    export default {
    props: ['lang', 'category', 'favs', 'listBrands', 'game_type'],
        data() {
            return {

            }
        },
        methods: {
            listProviders(game_type) {
                this.listBrands(game_type);
            },
            listFavs(category) {
                this.favs(category);
            },
            tr(code, lang) {
                return Ut.tr(code, lang);
            },
        },
        mounted() {

        }
    };

</script>

<template>
    <div id="sidebar-menu" class="desplegables">
        <!-- FAVORITOS -->
        <div class="favorito-btn" @click="listFavs(category)">
            <span>{{ tr("FAVS", lang) }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart"
                viewBox="0 0 16 16">
                <path
                    d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
            </svg>
        </div>
        <!-- FIN DE FAVORITOS -->
        <!-- PROVEEDORES -->
        <div class="column-mb-6-search-form ">
            <div class="jackpot-list-2 dropdown-toggle">
                <a role="button" href="#" @click="listProviders(game_type)">{{ tr("PROV", lang) }}</a>
            </div>
        </div>
        <!-- FIN DE PROVEEDORES -->
    </div>
</template>

<style>
.desplegables {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.1rem;
    width: 100%;
}

.favorito-btn {
    text-align: center;
    background: #b53c3c;
    color: white;
    font-weight: normal;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}
.jackpot-list-2 {
    line-height: 2;
    color: snow;
    padding: 0.25rem 0;
    text-align: center;
    padding-left: 0px;
    font-weight: bold;
    position: relative;
    background-color: #3a434a;
}

.jackpot-list-2.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    position: absolute;
    right: 26px;
    top: 19px;
}

.jackpot-list-2 a {
    color: #fff;
    text-decoration: none;
    display: block;
    font-weight: normal;
}
</style>